import React, { useState } from 'react';
import { updateOrderInfo } from '../../api/methods';
import { getAddressSuggestions } from '../../api/api';

const AddressInput = ({ cart, setCurrentCart, retailerId }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [textValue, setTextValue] = useState(
        cart.address.city !== ''
            ? `${cart.address.street1}, ${cart.address.city}, ${cart.address.state} ${cart.address.zip}`
            : ''
    );

    return (
        <>
            <input
                type='text'
                onChange={async (e) => {
                    setTextValue(e.target.value);

                    if (e.target.value !== '') {
                        const response = await getAddressSuggestions(
                            encodeURIComponent(e.target.value)
                        );

                        if (response.results) {
                            setSuggestions(
                                response.results.map((result) => result.address)
                            );
                        }
                    }
                }}
                value={textValue}
            ></input>

            {cart.address.city === '' ? (
                <code>Please enter address</code>
            ) : (
                !cart.address.deliverable && (
                    <code>Address is outside delivery range!</code>
                )
            )}

            <ul>
                {suggestions.map((suggestion, i) => (
                    <li
                        key={i}
                        onClick={async (e) => {
                            setTextValue(e.target.innerText);
                            setSuggestions([]);
                            setCurrentCart(
                                await updateOrderInfo(
                                    retailerId,
                                    cart.id,
                                    cart.orderType,
                                    cart.pricingType,
                                    {
                                        city: suggestion.municipality,
                                        state: suggestion.countrySubdivision,
                                        street1: `${suggestion.streetNumber} ${suggestion.streetName}`,
                                        street2: '',
                                        zip: suggestion.postalCode,
                                    }
                                )
                            );
                        }}
                    >
                        {suggestion.freeformAddress}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default AddressInput;
