import React from 'react';
import WithBlaze from './WithBlaze';
import CategoryCard from './CategoryCard';
import { blazeConfigCategories } from '../../config';

const CategoryCards = ({
    categories,
    images,
    setSelectedFilters,
    setShowSliders,
    retailerMenuLink,
    type
}) => {
    return (
        <div
            className={
                'headless-products-container headless-slider headless-loop-slider blaze-slider no-filter'
            }
            data-blaze={JSON.stringify(blazeConfigCategories())}
        >
            <WithBlaze>
                {categories.map((cat, i) => (
                    <CategoryCard
                        key={i}
                        category={cat}
                        image={images[cat]}
                        setSelectedFilters={setSelectedFilters}
                        setShowSliders={setShowSliders}
                        type={type}
                        retailerMenuLink={retailerMenuLink}
                    />
                ))}
            </WithBlaze>
        </div>
    );
};

export default CategoryCards;
