import React, { useState } from 'react';
import { removeItem, updateQuantity } from '../../api/methods';
import { getExistingCheckout } from '../../util';

const CartProduct = ({ items, retailerId, cartId, setCurrentCart }) => {
    const [errors, setErrors] = useState([]);

    const checkout = getExistingCheckout();

    return (
        <div className='headless-cart-sidebar-item'>
            <div id={items[0].productId} className='headless-cart-product-top'>
                <div className='headless-cart-product-image'>
                    <img src={items[0].product.image} />
                </div>
                <div className='headless-cart-product-title'>
                    {items[0].product.name}
                </div>
            </div>

            <div
                id={`item-${items[0].productId}`}
                className='headless-cart-sidebar-table'
            >
                {items.map((item, i) => {
                    let variantId = `${item.productId}~${item.option}`;

                    let variantQuantity = item.product.variants.find(
                        (variant) => variant.id === variantId
                    ).quantity;

                    return (
                        <div className='cart-variant-single' key={i}>
                            <div>{item.option}</div>
                            <div className='d-flex'>
                                <svg
                                    className='cart-variant-remove'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 448 512'
                                    onClick={async () =>
                                        setCurrentCart(
                                            await removeItem(
                                                checkout.retailerId,
                                                checkout.checkoutId,
                                                item.id,
                                                variantId
                                            )
                                        )
                                    }
                                >
                                    <path d='M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80h13.7H416h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H412.4L388.4 452.7c-2.5 33.4-30.3 59.3-63.8 59.3H123.4c-33.5 0-61.3-25.9-63.8-59.3L35.6 128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80.1 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM83.7 128l23.8 321.2c.6 8.4 7.6 14.8 16 14.8H324.6c8.4 0 15.3-6.5 16-14.8L364.3 128H83.7zm62.2 81.9c7.8-7.8 20.5-7.8 28.3 0L224 259.7l49.9-49.9c7.8-7.8 20.5-7.8 28.3 0s7.8 20.5 0 28.3L252.3 288l49.9 49.9c7.8 7.8 7.8 20.5 0 28.3s-20.5 7.8-28.3 0L224 316.3l-49.9 49.9c-7.8 7.8-20.5 7.8-28.3 0s-7.8-20.5 0-28.3L195.7 288l-49.9-49.9c-7.8-7.8-7.8-20.5 0-28.3z' />
                                </svg>
                                <select
                                    className='form-select headless-dropdown cart-quantity-selector'
                                    value={item.quantity}
                                    onChange={async (e) => {
                                        const response = await updateQuantity(
                                            checkout.retailerId,
                                            checkout.checkoutId,
                                            item.id,
                                            Number(e.target.value)
                                        );

                                        if (response.hasOwnProperty('errors')) {
                                            setErrors(
                                                response.errors.map((error) => {
                                                    if (
                                                        error?.extensions
                                                            ?.errors
                                                    ) {
                                                        return error.extensions.errors.map(
                                                            (extError) =>
                                                                extError.detail
                                                        );
                                                    } else {
                                                        return error.message;
                                                    }
                                                })
                                            );
                                        } else {
                                            setCurrentCart(response);
                                            setErrors([]);
                                        }
                                    }}
                                >
                                    {/* the quantity properties are confusing. item.quantity is the current amount in the cart, and item.product.variants.quantity *seems* to be the max quantity available, but you can add more than that, so ¯\_(ツ)_/¯ */}
                                    {[
                                        ...Array(
                                            variantQuantity > item.quantity
                                                ? variantQuantity
                                                : item.quantity
                                        ),
                                    ].map((_, i) => (
                                        <option value={i + 1} key={i + 1}>
                                            {i + 1}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    );
                })}
                {errors.length > 0 &&
                    errors.map((error, i) => (
                        <small key={i}>
                            <code>{error}</code>
                        </small>
                    ))}
            </div>
        </div>
    );
};

export default CartProduct;
