import React, { useState } from 'react';
import { filterTypes } from '../../config';
import startCase from 'lodash.startcase';

const FilterModuleDesktop = ({
    title,
    filterType,
    filters,
    selectedFilters,
    handleChange,
}) => {
    const [toggleFilterCaret, setToggleFilterCaret] = useState(true);

    return (
        <div
            className={`filter-sidebar-module ${
                toggleFilterCaret ? '' : 'filter-inactive'
            }`}
        >
            <div className='filter-sidebar-header'>
                <div className='filter-sidebar-title'>{title}</div>
                <div
                    className='filter-sidebar-caret'
                    role='button'
                    onClick={() => setToggleFilterCaret(!toggleFilterCaret)}
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'
                    >
                        <path d='M212.7 331.3c6.2 6.2 16.4 6.2 22.6 0l160-160c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4 75.3 148.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l160 160z' />
                    </svg>
                </div>
            </div>

            <div className='filter-sidebar-options'>
                {filters.map(
                    (filter, i) =>
                        filter !== 'NOT_APPLICABLE' &&
                        filter !== 'DEFAULT' && (
                            <div key={i} className='filter-option'>
                                <input
                                    className='headless_category_checkbox headless_filter_checkbox'
                                    type='checkbox'
                                    checked={
                                        (filter === filterTypes.specials &&
                                            selectedFilters[
                                                filterTypes.specials
                                            ]) ||
                                        (filter === filterTypes.staff_picks &&
                                            selectedFilters[
                                                filterTypes.staff_picks
                                            ]) ||
                                        selectedFilters[filterType] ===
                                            filter ||
                                        selectedFilters[filterType] ===
                                            filter?.id ||
                                        selectedFilters?.effects.includes(
                                            filter
                                        )
                                    }
                                    id={`headless_category_${
                                        filter?.name || filter
                                    }`}
                                    name={`headless_category_${
                                        filter?.name || filter
                                    }`}
                                    value={filter?.id || filter}
                                    onChange={(e) =>
                                        handleChange(e.target, filterType)
                                    }
                                />
                                <label
                                    htmlFor={`headless_category_${
                                        filter?.name || filter
                                    }`}
                                >
                                    {startCase(
                                        filter?.name
                                            ? filter.name.toLowerCase()
                                            : filter.toLowerCase()
                                    )}
                                </label>
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

export default FilterModuleDesktop;
