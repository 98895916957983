import React from 'react';
import FilterModuleMobile from './FilterModuleMobile';
import { filterTypes, menuFilter } from '../../config';

const FilterMobile = ({
    selectedFilters,
    setSelectedFilters,
    filterTerms,
    handleChange,
    styleOptions,
}) => {
    const { categories, subcategories, strains, brands, effects } = filterTerms;

    return (
        <div id='headless-filter-mobile' className='headless-filter-mobile'>
            <FilterModuleMobile
                title={'All Categories'}
                filterType={filterTypes.category}
                filters={categories}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />

            <FilterModuleMobile
                title={'All Subcategories'}
                filterType={filterTypes.subcategory}
                filters={subcategories}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />

            <FilterModuleMobile
                title={'All Strains'}
                filterType={filterTypes.strain}
                filters={strains}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />

            <FilterModuleMobile
                title={'All Effects'}
                filterType={filterTypes.effects}
                filters={effects}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                multiple={true}
                handleChange={handleChange}
            />

            <FilterModuleMobile
                title={'All Brands'}
                filterType={filterTypes.brand}
                filters={brands}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />

            <button
                id='clear-dropdown-filters'
                className={`${styleOptions.buttonClass ? styleOptions.buttonClass + ' btn-sm' : ''}`}
                onClick={() => {
                    setSelectedFilters(menuFilter);
                }}
            >
                Clear Filters
            </button>
        </div>
    );
};

export default FilterMobile;
