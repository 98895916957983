import React, { useState, useEffect } from 'react';
import ProductsGrid from '../components/ProductsGrid/ProductsGrid';
import { shortcodeAttsHelper, getInitialMenuFilter } from '../util';
import { menuFilter } from '../config';
import CartSidebar from '../components/Cart/CartSidebar';
import {
    getRetailerData,
    getOrCreateCart,
    getSpecialsInfo,
} from '../api/methods';
import LoadingSpinner from '../components/LoadingSpinner';
import CategoriesSlider from '../components/sliders/CategoriesSlider';
import CategorySlider from '../components/sliders/CategorySlider';
import BrandSlider from '../components/sliders/BrandSlider';
import StaffPicksSlider from '../components/sliders/StaffPicksSlider';
import SpecialsSlider from '../components/sliders/SpecialsSlider';

const Shop = ({ data }) => {
    const {
        retailerId,
        shortcodeAtts,
        shopEditor: { useMenuBuilder, menuBuilder, categoryImages },
        retailerMenuLink,
        filterTerms,
        customStyleOptions,
    } = data;

    const atts = shortcodeAttsHelper(shortcodeAtts);
    const params = new URLSearchParams(window.location.search);

    const [selectedFilters, setSelectedFilters] = useState(
        getInitialMenuFilter(atts.menuFilter, params)
    );

    // create a copy of initial shortcode atts to refer back to.
    const initialAtts = Object.assign({}, atts);

    const [currentCart, setCurrentCart] = useState(null);
    const [retailerData, setRetailerData] = useState(null);
    const [toggleCart, setToggleCart] = useState(false);

    const [specials, setSpecials] = useState(null);
    const [specialId, setSpecialId] = useState(params.get('special'));

    const [showMenuBuilderSliders, setShowMenuBuilderSliders] = useState(
        useMenuBuilder && atts.menu
    );
    const [showShortcodeSliders, setShowShortcodeSliders] = useState(
        (atts.menuFilter.category === 'ALL' || atts.specials) &&
            params.size === 0
    );

    if (showMenuBuilderSliders && params.size === 0) {
        atts.slider = true;
        atts.menuType = null;
        atts.title = null;
    }

    useEffect(() => {
        if (!showMenuBuilderSliders) {
            atts.slider = false;
            atts.menuType = initialAtts.menuType;
            atts.title = initialAtts.title;
        }
    }, [showMenuBuilderSliders]);

    /**
     * Get cart or create new one.
     * Get retailer data.
     * Provides an `AbortSignal` to avoid doubling-up on API calls during re-renders.
     *
     * Runs only on initial pageload.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetRetailerData = async () => {
            setRetailerData(await getRetailerData(retailerId, signal));
        };

        const getAndSetCart = async () => {
            setCurrentCart(await getOrCreateCart(retailerId, signal));
        };

        const getAndSetSpecials = async () => {
            setSpecials(await getSpecialsInfo(retailerId, signal));
        };

        if (atts.specials || specialId) {
            getAndSetSpecials();
        } else {
            menuBuilder.forEach((menuItem) => {
                if (menuItem?.specials) {
                    getAndSetSpecials();
                }
            });
        }

        getAndSetCart();
        getAndSetRetailerData();

        return () => controller.abort();
    }, []);

    return (
        <>
            {currentCart && retailerData && (
                <CartSidebar
                    retailerId={retailerId}
                    retailerData={retailerData}
                    cart={currentCart}
                    setCurrentCart={setCurrentCart}
                    toggleCart={toggleCart}
                    setToggleCart={setToggleCart}
                />
            )}
            {showShortcodeSliders
                ? atts.menuFilter.category === 'ALL' && (
                      <CategoriesSlider
                          images={categoryImages}
                          atts={atts}
                          setSelectedFilters={setSelectedFilters}
                          allCategories={filterTerms.categories}
                          retailerMenuLink={retailerMenuLink}
                          type='shortcode'
                      />
                  )
                : null}
            {showShortcodeSliders
                ? atts.specials &&
                  specials && (
                      <SpecialsSlider
                          atts={atts}
                          filter={{
                              ...menuFilter,
                              specials: true,
                          }}
                          selectedFilters={selectedFilters}
                          setSelectedFilters={setSelectedFilters}
                          setShowMenuBuilderSliders={setShowMenuBuilderSliders}
                          specials={specials}
                          setSpecialId={setSpecialId}
                          retailerMenuLink={retailerMenuLink}
                          type='shortcode'
                          styleOptions={customStyleOptions}
                      />
                  )
                : null}
            {showMenuBuilderSliders && currentCart && params.size === 0 ? (
                menuBuilder.map((menuItem, i) => {
                    switch (menuItem.acf_fc_layout) {
                        case 'categories_slider':
                            return (
                                <CategoriesSlider
                                    key={i}
                                    menuItem={menuItem}
                                    images={categoryImages}
                                    atts={atts}
                                    setSelectedFilters={setSelectedFilters}
                                    setShowSliders={setShowMenuBuilderSliders}
                                    type='menu-builder'
                                    retailerMenuLink={retailerMenuLink}
                                />
                            );
                        case 'single_category_slider':
                            return (
                                <CategorySlider
                                    key={i}
                                    menuItem={menuItem}
                                    data={data}
                                    atts={atts}
                                    filter={{
                                        ...menuFilter,
                                        category: menuItem.category,
                                    }}
                                    params={params}
                                    cartId={currentCart.id}
                                    setToggleCart={setToggleCart}
                                    setCurrentCart={setCurrentCart}
                                    menuCategory={menuItem.category}
                                    selectedFilters={selectedFilters}
                                    setSelectedFilters={setSelectedFilters}
                                    setShowMenuBuilderSliders={
                                        setShowMenuBuilderSliders
                                    }
                                    styleOptions={customStyleOptions}
                                />
                            );
                        case 'brand_slider':
                            let brand = JSON.parse(menuItem.brand.value);

                            if (brand[retailerId]) {
                                return (
                                    <BrandSlider
                                        key={i}
                                        menuItem={menuItem}
                                        data={data}
                                        atts={atts}
                                        filter={{
                                            ...menuFilter,
                                            brandId: brand[retailerId],
                                        }}
                                        params={params}
                                        cartId={currentCart.id}
                                        setToggleCart={setToggleCart}
                                        setCurrentCart={setCurrentCart}
                                        selectedFilters={selectedFilters}
                                        setSelectedFilters={setSelectedFilters}
                                        setShowMenuBuilderSliders={
                                            setShowMenuBuilderSliders
                                        }
                                    />
                                );
                            } else {
                                console.error(
                                    'Selected Brand does not exist for current retailer.',
                                    {
                                        label: menuItem.brand.label,
                                        options: brand,
                                        retailerId,
                                    }
                                );
                            }
                            break;
                        case 'staff_picks_slider':
                            if (menuItem.staff_picks) {
                                return (
                                    <StaffPicksSlider
                                        key={i}
                                        menuItem={menuItem}
                                        data={data}
                                        atts={atts}
                                        filter={{
                                            ...menuFilter,
                                            staff_picks: true,
                                        }}
                                        params={params}
                                        cartId={currentCart.id}
                                        setToggleCart={setToggleCart}
                                        setCurrentCart={setCurrentCart}
                                        selectedFilters={selectedFilters}
                                        setSelectedFilters={setSelectedFilters}
                                        setShowMenuBuilderSliders={
                                            setShowMenuBuilderSliders
                                        }
                                    />
                                );
                            }
                            break;
                        case 'specials_slider':
                            if (menuItem.specials && specials) {
                                return (
                                    <SpecialsSlider
                                        key={i}
                                        atts={atts}
                                        filter={{
                                            ...menuFilter,
                                            specials: true,
                                        }}
                                        menuItem={menuItem}
                                        selectedFilters={selectedFilters}
                                        setSelectedFilters={setSelectedFilters}
                                        setShowMenuBuilderSliders={
                                            setShowMenuBuilderSliders
                                        }
                                        specials={specials}
                                        setSpecialId={setSpecialId}
                                        retailerMenuLink={retailerMenuLink}
                                        type='menu-builder'
                                        styleOptions={customStyleOptions}
                                    />
                                );
                            }
                            break;
                        default:
                            break;
                    }
                })
            ) : (
                <>
                    {!showShortcodeSliders && currentCart && selectedFilters ? (
                        <ProductsGrid
                            data={data}
                            atts={atts}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            params={params}
                            cartId={currentCart.id}
                            setToggleCart={setToggleCart}
                            setCurrentCart={setCurrentCart}
                            specialId={specialId}
                            specials={specials}
                            retailerData={retailerData}
                        />
                    ) : (
                        <>{!showShortcodeSliders && <LoadingSpinner />}</>
                    )}
                </>
            )}
        </>
    );
};

export default Shop;
