import App from './App';
import { createRoot, render } from '@wordpress/element';

/**
 * Import the stylesheet for the plugin.
 */
import './style/main.scss';

if (createRoot) {
    Object.entries(rangeHeadlessData).forEach(([key, data]) => {
        createRoot(document.getElementById(`range-headless-${key}`)).render(
            <React.StrictMode>
                <App data={data} />
            </React.StrictMode>
        );
    });
} else {
    Object.entries(rangeHeadlessData).forEach(([key, data]) => {
        render(
            <React.StrictMode>
                <App data={data} />
            </React.StrictMode>,
            document.getElementById(`range-headless-${key}`)
        );
    });
}
