import React from 'react';
import MenuBanner from './MenuBanner';
import MenuOverlay from './MenuOverlay';
import ProductBanner from './ProductBanner';
import CartBanner from './CartBanner';
import CheckoutBanner from './CheckoutBanner';

const Surfside = ({
    accountId, sourceId, retailerId, placement, placementId, category
}) => {
    switch (placement) {
        case 'menu-banner':
            return (
                <MenuBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
          );
        case 'menu-overlay':
            return (
                <MenuOverlay
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'category-banner':
            return (
                <MenuBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    category={category}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'product':
            return (
                <ProductBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'cart':
            return (
                <CartBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
        case 'checkout':
            return (
                <CheckoutBanner
                    accountId={accountId}
                    sourceId={sourceId}
                    retailerId={retailerId}
                    placementId={placementId}
                />
            );
      default:
          return null;
    }
};

export default Surfside;
