import { getExistingCheckout, cleanMenuFilterForQuery } from '../util';
import {
    createCheckout,
    fetchCartDetails,
    menuQuery,
    addItemToCheckout,
    removeItemFromCheckout,
    productQuery,
    getSpecialsList,
    menuBySpecialsSection,
    menuByCustomSection,
    updateItemQuantity,
    updateCheckout,
    retailerQuery,
    menuByStaffPicks,
} from './api';

/**
 * Gets existing cart from localstorage data, or creates a new cart.
 *
 * @param {string} retailerId Current retail id.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getOrCreateCart = async (retailerId, signal = null) => {
    let cart;
    const existingCheckout = getExistingCheckout();

    // if cart info exists.
    if (existingCheckout) {
        const { checkoutId } = existingCheckout;
        const lsRetailerId = existingCheckout.retailerId;

        try {
            const response = await fetchCartDetails(
                lsRetailerId,
                checkoutId,
                signal
            );

            if (response && response.hasOwnProperty('errors')) {
                localStorage.removeItem('DUTCHIE_CHECKOUT');

                try {
                    const response = await createCheckout(
                        retailerId,
                        'PICKUP',
                        'RECREATIONAL',
                        signal
                    );

                    cart = response?.data.createCheckout;

                    // set localStorage.
                    window.localStorage.setItem(
                        'DUTCHIE_CHECKOUT',
                        JSON.stringify({
                            checkoutId: cart.id,
                            retailerId: retailerId,
                        })
                    );
                } catch (error) {
                    return console.error('error', error);
                }
            } else {
                cart = response?.data.checkout;
            }
        } catch (error) {
            return console.error('error', error);
        }
        // or, new cart.
    } else {
        try {
            const response = await createCheckout(
                retailerId,
                'PICKUP',
                'RECREATIONAL',
                signal
            );

            cart = response.data.createCheckout;

            // set localStorage.
            window.localStorage.setItem(
                'DUTCHIE_CHECKOUT',
                JSON.stringify({
                    checkoutId: cart.id,
                    retailerId: retailerId,
                })
            );
        } catch (error) {
            return console.log('error', error);
        }
    }

    return cart;
};

/**
 * Get all menu products from a retailer.
 *
 * @param {string} retailerId Current retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {menuFilter} object menuFilter config.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getProducts = async (
    retailerId,
    offset,
    limit,
    menuFilter,
    signal = null
) => {
    try {
        const response = await menuQuery(
            retailerId,
            offset,
            limit,
            cleanMenuFilterForQuery(menuFilter),
            signal
        );

        if (response && response?.hasOwnProperty('errors')) {
            console.error(
                response.errors,
                'Does the retailerId provided to the shortcode correspond with the current API? Or perhaps the provided menuFilter is incorrect?',
                { menuFilter: cleanMenuFilterForQuery(menuFilter) }
            );
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get specials list by Retail ID.
 *
 * @param {string} retailerId Retail ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getSpecialsInfo = async (retailerId, signal = null) => {
    try {
        const response = await getSpecialsList(retailerId, signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.specials;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get specials menu.
 *
 * @param {string} retailerId Retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {array} specialId Specials ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getSpecialsMenu = async (
    retailerId,
    offset,
    limit,
    specialId,
    signal = null
) => {
    try {
        const response = await menuBySpecialsSection(
            retailerId,
            offset,
            limit,
            specialId,
            signal
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get custom menu.
 *
 * @param {string} retailerId Retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {string} sectionType Section type 'CUSTOM_TYPE'.
 * @param {string} sectionName Section name 'custom section name'.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getCustomMenu = async (
    retailerId,
    offset,
    limit,
    sectionType,
    sectionName,
    signal = null
) => {
    try {
        const response = await menuByCustomSection(
            retailerId,
            offset,
            limit,
            sectionType,
            sectionName,
            signal
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get staff picks menu.
 *
 * @param {string} retailerId Retail ID.
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {string} menuSectionFilterType Menu filter type. Default 'STAFF_PICKS'.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getStaffPicksMenu = async (
    retailerId,
    offset,
    limit,
    menuSectionFilterType = 'STAFF_PICKS',
    signal = null
) => {
    try {
        const response = await menuByStaffPicks(
            retailerId,
            offset,
            limit,
            menuSectionFilterType,
            signal
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.menu?.products;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Add item to checkout object.
 *
 * @param {string} retailerId Current retail ID.
 * @param {string} cartId Current cart ID.
 * @param {int} quantity Quantity to add.
 * @param {string} variantId Variant ID.
 *
 * @returns {void|error}
 */
export const addItem = async (retailerId, cartId, quantity, variantId) => {
    // split into product id and variant option.
    const variant = variantId.split('~');
    let newCartId = false;

    const existingCheckout = getExistingCheckout();

    // if cart info exists.
    if (existingCheckout) {
        const lsRetailerId = existingCheckout.retailerId;

        // check if existing cart for different retail id.
        if (retailerId !== lsRetailerId) {
            const newCart = confirm(
                'You have an existing cart for another retail location. Click "OK" to erase existing cart and proceed with new retail location.'
            );

            if (newCart) {
                window.localStorage.removeItem('DUTCHIE_CHECKOUT');
                let newCart = await getOrCreateCart(retailerId);
                newCartId = newCart.id;
            }
        }
    }

    try {
        const response = await addItemToCheckout(
            retailerId,
            newCartId || cartId,
            variant[0],
            quantity,
            variant[1]
        );

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
            return response;
        } else {
            return response.data.addItem;
        }
    } catch (error) {
        console.log('error', error);
    }
};

/**
 * Update item quantity.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} itemId
 * @param {int} quantity
 *
 * @returns {object|error}
 */
export const updateQuantity = async (
    retailerId,
    checkoutId,
    itemId,
    quantity
) => {
    const response = await updateItemQuantity(
        retailerId,
        checkoutId,
        itemId,
        quantity
    );

    try {
        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
            return response;
        }

        return response?.data?.updateQuantity;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Update checkout object.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} orderType
 * @param {string} pricingType
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const updateOrderInfo = async (
    retailerId,
    checkoutId,
    orderType,
    pricingType,
    address = {
        city: '',
        state: '',
        street1: '',
        street2: '',
        zip: '',
    },
    signal = null
) => {
    const response = await updateCheckout(
        retailerId,
        checkoutId,
        orderType,
        pricingType,
        address,
        signal
    );

    try {
        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }

        return response?.data?.updateCheckout;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Remove item from checkout object.
 *
 * @param {string} retailerId Current retail ID.
 * @param {string} cartId Current cart ID.
 * @param {string} itemId Item ID.
 *
 * @returns {object|void|error}
 */
export const removeItem = async (retailerId, cartId, itemId) => {
    try {
        const response = await removeItemFromCheckout(
            retailerId,
            cartId,
            itemId
        );

        if (response && response.hasOwnProperty('errors')) {
            return console.error(response.errors);
        }

        return response.data.removeItem;
    } catch (error) {
        console.log('error', error);
    }
};

/**
 * Get single product.
 *
 * @param {string} retailerId Retail ID.
 * @param {string} productId Product ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getProduct = async (retailerId, productId, signal = null) => {
    try {
        const response = await productQuery(retailerId, productId, signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(response.errors);
        }
        console.log(response?.data?.product);
        return response?.data?.product;
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Get single product.
 *
 * @param {string} retailerId Retail ID.
 * @param {AbortSignal|null} signal
 *
 * @returns {object|error}
 */
export const getRetailerData = async (retailerId, signal = null) => {
    try {
        const response = await retailerQuery(retailerId, signal);

        if (response && response.hasOwnProperty('errors')) {
            console.error(
                response.errors,
                'Does the retailerId provided to the shortcode correspond with the current API?'
            );
        }

        return response?.data?.retailer;
    } catch (error) {
        return console.log('error', error);
    }
};
