import React from 'react';
import CategoryCards from '../Shop/CategoryCards';
import { filterTypes } from '../../config';

const CategoriesSlider = ({
    menuItem = null,
    images,
    atts,
    setSelectedFilters,
    setShowSliders = null,
    allCategories = null,
    type,
    retailerMenuLink,
}) => {
    return (
        <div className='headless-menu-builder-section'>
            <div className='headless-menu-builder-title'>
                <div className='headless-shortcode-title-wrapper'>
                    <div className='headless-shortcode-title'>
                        {menuItem &&
                        menuItem.headless_menu_builder_categories_emoji ? (
                            <div className='shortcode-title-emoji-left'>
                                {
                                    menuItem.headless_menu_builder_categories_emoji
                                }
                            </div>
                        ) : null}
                        {atts.title ? (
                            <span>{atts.title}</span>
                        ) : (
                            <span>Categories</span>
                        )}
                        {menuItem &&
                        menuItem.headless_menu_builder_categories_emoji ? (
                            <div className='shortcode-title-emoji-right'>
                                {
                                    menuItem.headless_menu_builder_categories_emoji
                                }
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <CategoryCards
                categories={
                    allCategories?.filter(
                        (cat) =>
                            cat !== 'NOT_APPLICABLE' &&
                            cat !== filterTypes.specials &&
                            cat !== filterTypes.staff_picks
                    ) || menuItem.categories
                }
                images={images}
                atts={atts}
                setSelectedFilters={setSelectedFilters}
                setShowSliders={setShowSliders}
                retailerMenuLink={retailerMenuLink}
                type={type}
            />
        </div>
    );
};

export default CategoriesSlider;
