import React, { useState } from 'react';
import { updateOrderInfo } from '../../api/methods';
import { getExistingCheckout } from '../../util';

const OrderType = ({ cart, setCurrentCart, fulfillmentOptions }) => {
    const { pickup, curbsidePickup, delivery, driveThruPickup } =
        fulfillmentOptions;
    const [checked, setChecked] = useState(cart.orderType);

    const checkout = getExistingCheckout();

    const onRadioChange = async (e) => {
        setChecked(e.target.value);

        const response = await updateOrderInfo(
            checkout.retailerId,
            checkout.checkoutId,
            e.target.value,
            cart.pricingType
        );

        if (response) {
            setCurrentCart(response);
        }
    };

    return (
        <>
            {(pickup || curbsidePickup || driveThruPickup || delivery) && (
                <div className='headless-cart-ordertype'>
                    {(pickup || curbsidePickup || driveThruPickup) && (
                        <div className='ordertype-option'>
                            <input
                                type='radio'
                                value='PICKUP'
                                name='orderType'
                                id='order-pickup'
                                checked={checked === 'PICKUP'}
                                onChange={(e) => onRadioChange(e)}
                            />
                            <label htmlFor='order-pickup'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 448 512'
                                >
                                    <path d='M160 112v48H288V112c0-35.3-28.7-64-64-64s-64 28.7-64 64zm-48 96H48V464H400V208H336v56 24H288V264 208H160v56 24H112V264 208zm0-48V112C112 50.1 162.1 0 224 0s112 50.1 112 112v48h64 48v48V464v48H400 48 0V464 208 160H48h64z' />
                                </svg>
                                <span>Pickup</span>
                            </label>
                        </div>
                    )}

                    {delivery && (
                        <div className='ordertype-option'>
                            <input
                                type='radio'
                                value='DELIVERY'
                                name='orderType'
                                id='order-delivery'
                                checked={checked === 'DELIVERY'}
                                onChange={(e) => onRadioChange(e)}
                            />
                            <label htmlFor='order-delivery'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M127.7 106.8L103.4 176H408.6l-24.2-69.2c-5.6-16-20.8-26.8-37.8-26.8H165.4c-17 0-32.1 10.7-37.8 26.8zm-79.6 82L82.3 90.9C94.7 55.6 128 32 165.4 32H346.6c37.4 0 70.7 23.6 83.1 58.9l34.3 97.9C492.6 205.4 512 236.4 512 272v80 48 56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400H48v56c0 13.3-10.7 24-24 24s-24-10.7-24-24V400 352 272c0-35.6 19.3-66.6 48.1-83.2zM416 224H96c-26.5 0-48 21.5-48 48v80H464V272c0-26.5-21.5-48-48-48zM112 256a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm256 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z' />
                                </svg>
                                <span>Delivery</span>
                            </label>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default OrderType;
