import React from 'react';
import ProductsGrid from '../ProductsGrid/ProductsGrid';

const BrandSlider = ({
    data,
    menuItem,
    atts,
    filter,
    params,
    cartId,
    setToggleCart,
    setCurrentCart,
    selectedFilters,
    setSelectedFilters,
    setShowMenuBuilderSliders,
}) => {
    return (
        <div className='headless-menu-builder-section'>
            <div className='headless-menu-builder-title'>
                <div className='headless-shortcode-title-wrapper'>
                    <div className='headless-shortcode-title'>
                        {menuItem.headless_menu_builder_brand_emoji ? (
                            <div className='shortcode-title-emoji-left'>
                                {menuItem.headless_menu_builder_brand_emoji}
                            </div>
                        ) : null}
                        <span>{menuItem.brand.label}</span>
                        {menuItem.headless_menu_builder_brand_emoji ? (
                            <div className='shortcode-title-emoji-right'>
                                {menuItem.headless_menu_builder_brand_emoji}
                            </div>
                        ) : null}
                    </div>
                    <a
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSelectedFilters(filter);
                            setShowMenuBuilderSliders(false);
                        }}
                        className='headless-button headless-button-sm headless-title-button'
                    >
                        Shop all {menuItem.brand.label}
                    </a>
                </div>
            </div>
            <ProductsGrid
                data={data}
                atts={atts}
                menuFilter={filter}
                params={params}
                cartId={cartId}
                setToggleCart={setToggleCart}
                setCurrentCart={setCurrentCart}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
            />
        </div>
    );
};

export default BrandSlider;
