import React from 'react';
import FilterModuleDesktop from './FilterModuleDesktop';
import { filterTypes, menuFilter } from '../../config';

const FilterDesktop = ({
    selectedFilters,
    setSelectedFilters,
    filterTerms,
    handleChange,
    styleOptions,
}) => {
    const { categories, subcategories, strains, brands, effects } = filterTerms;

    return (
        <div id='headless-filter-sidebar' className='headless-filter-sidebar'>
            <FilterModuleDesktop
                title={'Category'}
                filterType={filterTypes.category}
                filters={categories}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />
            <FilterModuleDesktop
                title={'Subcategory'}
                filterType={filterTypes.subcategory}
                filters={subcategories}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />
            <FilterModuleDesktop
                title={'Strain'}
                filterType={filterTypes.strain}
                filters={strains}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />
            <FilterModuleDesktop
                title={'Effects'}
                filterType={filterTypes.effects}
                filters={effects}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />
            <FilterModuleDesktop
                title={'Brand'}
                filterType={filterTypes.brand}
                filters={brands}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                handleChange={handleChange}
            />

            <button
                id='clear-checkbox-filters'
                className={`${styleOptions.buttonClass ? styleOptions.buttonClass + ' btn-sm' : 'clear-checkbox-filters-button'}`}
                onClick={() => {
                    setSelectedFilters(menuFilter);
                }}
            >
                Clear Filters
            </button>
        </div>
    );
};

export default FilterDesktop;
