import React, { useState } from 'react';

const StoreBanner = ({ banner }) => {
    const [toggleBanner, setToggleBanner] = useState(false);
    const [showBanner, setShowBanner] = useState(false);

    return (
        <div
            className={`headless-shop-banner ${
                showBanner ? 'headless-d-none' : ''
            }`}
        >
            <div
                className='shop-banner-header'
            >
                <span>Store Notice</span>
                <span
                    className='shop-banner-icon'
                    onClick={() => setShowBanner(!showBanner)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                </span>
            </div>
            <div
                className={`shop-banner-content ${
                    toggleBanner ? 'show-banner' : ''
                }`}
            >
                {banner.html ? (
                    <div
                        className='shop-banner-inner-html'
                        dangerouslySetInnerHTML={{ __html: banner.html }}
                    />
                ) : null}
                <div
                    className='shop-banner-show-more'
                    onClick={() => setToggleBanner(!toggleBanner)}
                >
                    <div
                        className='shop-banner-more-button'
                    >
                        Read Full Notice
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreBanner;
