import React, { useState, useEffect } from 'react';
import { getOrCreateCart, getProduct, getRetailerData } from '../api/methods';
import { ucwords } from '../util';
import Images from '../components/SingleProduct/Images';
import Category from '../components/SingleProduct/Category';
import AddToCart from '../components/SingleProduct/AddToCart';
import Effects from '../components/SingleProduct/Effects';
import Cannabinoids from '../components/SingleProduct/Cannabinoids';
import Surfside from '../components/Surfside/Surfside';
import PotencyChart from '../components/SingleProduct/PotencyChart';
import CartSidebar from '../components/Cart/CartSidebar';
import LoadingSpinner from '../components/LoadingSpinner';

const SingleProduct = ({ data }) => {
    const {
        retailerId,
        productId,
        surfside,
        shopEditor: {
            singleProductOptions: {
                showPotencyChart,
                showProductDescription,
                showProductEffects,
                showStaffPickBadge,
            },
        },
        customStyleOptions,
    } = data;

    console.log(data);

    const [currentCart, setCurrentCart] = useState(null);
    const [product, setProduct] = useState(null);
    const [retailerData, setRetailerData] = useState(null);
    const [toggleCart, setToggleCart] = useState(false);

    /**
     * Get and set cart. Set document title.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetRetailerData = async () => {
            setRetailerData(await getRetailerData(retailerId, signal));
        };

        // also set document title & meta description once product is received.
        const getAndSetProduct = async () => {
            const theProduct = await getProduct(retailerId, productId, signal);
            const theRetailer = await getRetailerData(retailerId, signal);

            console.log("Product obj: " + theProduct);

            // remove ( and ) characters from shop title if found
            var retailerFormatted = theRetailer.name.replace("(", " - ");
            retailerFormatted = retailerFormatted.replace(")", "");

            if (theProduct && theRetailer) {
                document.title = `${theProduct.name} | Shop ${ucwords(theProduct.category)} | ${retailerFormatted}`;

                const metaDescription = 'Offered at ' + retailerFormatted + ', try ' + theProduct.name + ' from ' + theProduct.brand.name + ' today.';
                const metaDescriptionTag = document.querySelector('meta[name="description"]');

                if (metaDescriptionTag) {
                    metaDescriptionTag.setAttribute('content', metaDescription);
                }
            }

            setProduct(theProduct);
        };

        const getAndSetCart = async () => {
            setCurrentCart(await getOrCreateCart(retailerId, signal));
        };

        getAndSetRetailerData();
        getAndSetProduct();
        getAndSetCart();

        return () => controller.abort();
    }, []);

    return (
        <>
            {product && retailerData ? (
                <>
                    {currentCart && (
                        <CartSidebar
                            retailerId={retailerId}
                            cart={currentCart}
                            setCurrentCart={setCurrentCart}
                            toggleCart={toggleCart}
                            setToggleCart={setToggleCart}
                            retailerData={retailerData}
                        />
                    )}
                    {surfside.enable &&
                    surfside.showProductBanner ? (
                        <Surfside
                            placement='product'
                            accountId={surfside.account_id}
                            sourceId={surfside.source_id}
                            retailerId={retailerId}
                            placementId={surfside.placement_ids.single_product}
                        />
                    ) : null}
                    <div
                        data-retail-location-id={retailerId}
                        className='range-headless headless-single-product'
                    >
                        <div className='row'>
                            <Images images={product?.images} />
                            <div className='col-lg-6 col-xxl-7'>
                                <div className='headless-sp-primary'>
                                    <Category
                                        category={product?.category}
                                        strainType={product?.strainType}
                                    />
                                    <h1 className='headless-sp-title pe-lg-5'>
                                        {product?.name}
                                    </h1>
                                    {product?.brand && (
                                        <div className='headless-sp-brand'>
                                            <span>By </span>
                                            <span>
                                                <a href='#'>
                                                    {product?.brand?.name}
                                                </a>
                                            </span>
                                        </div>
                                    )}
                                    {currentCart && (
                                        <AddToCart
                                            variants={product?.variants}
                                            retailerId={retailerId}
                                            cart={currentCart}
                                            setCurrentCart={setCurrentCart}
                                            setToggleCart={setToggleCart}
                                            styleOptions={customStyleOptions}
                                        />
                                    )}
                                    {showProductEffects && (
                                        <Effects effects={product?.effects} />
                                    )}
                                    {showPotencyChart && (
                                        <PotencyChart
                                            potencyThc={product?.potencyThc}
                                            potencyCbd={product?.potencyCbd}
                                        />
                                    )}
                                    {showProductDescription && (
                                        <div className='headless-sp-description'>
                                            <div className='headless-breadcrumb'>
                                                Product Description
                                            </div>
                                            <div className='headless-sp-content'>
                                                {product?.description}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

export default SingleProduct;
