import React, { useEffect, useState } from 'react';
import { getOrCreateCart, getRetailerData } from '../api/methods';
import LoadingSpinner from '../components/LoadingSpinner';
import PickupOrDelivery from '../components/Cart/PickupOrDelivery';
import RetailerInfo from '../components/Cart/RetailerInfo';
import CartProducts from '../components/Cart/CartProducts';
import Surfside from '../components/Surfside/Surfside';
import { getExistingCheckout } from '../util';

const Cart = ({ data }) => {
    const [currentCart, setCurrentCart] = useState(null);
    const [retailerData, setRetailerData] = useState(null);
    const {
        surfside,
        customStyleOptions,
    } = data;

    /**
     * Get and set cart and retailer data using IDs from localStorage.
     * Provides an `AbortSignal` to avoid doubling-up on API calls during re-renders.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetRetailerData = async (retailerId) => {
            setRetailerData(await getRetailerData(retailerId, signal));
        };

        const getAndSetCart = async (retailerId) => {
            setCurrentCart(await getOrCreateCart(retailerId, signal));
        };

        const checkout = getExistingCheckout();
        if (checkout) {
            const { retailerId } = checkout;

            getAndSetRetailerData(retailerId);
            getAndSetCart(retailerId);
        }

        return () => controller.abort();
    }, []);

    return (
        <div className='headless-cart-container'>
            {currentCart && retailerData ? (
            <>
            {surfside.enable &&
                surfside.showCartBanner ? (
                    <Surfside
                        placement='cart'
                        accountId={surfside.account_id}
                        sourceId={surfside.source_id}
                        retailerId={retailerData.id}
                        placementId={surfside.placement_ids.cart}
                    />
                ) : null}
                <div className='headless-cart-page'>
                    <div className='headless-cart-col headless-cart-left'>
                        <RetailerInfo retailerData={retailerData} />
                        <CartProducts
                            cart={currentCart}
                            retailerId={retailerData.id}
                            setCurrentCart={setCurrentCart}
                        />
                    </div>
                    <div className='headless-cart-col headless-cart-right'>
                        <PickupOrDelivery
                            cart={currentCart}
                            setCurrentCart={setCurrentCart}
                            retailerData={retailerData}
                            styleOptions={customStyleOptions}
                        />
                    </div>
                </div>
            </>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default Cart;
