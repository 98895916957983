/**
 * Calculates total items in cart.
 * @param {object} cart Current cart object.
 * @returns {number}
 */
export const totalItemsInCart = (cart) => {
    return cart?.items.map((item) => item.quantity).reduce((a, b) => a + b, 0);
};

/**
 * Gets and formats number into currency.
 *
 * @param {number} num
 *
 * @returns {string}
 */
export const formattedPrice = (num) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format((num / 100).toFixed(2));
};
