import React from 'react';
import ProductsGrid from '../ProductsGrid/ProductsGrid';
import { ucwords } from '../../util';

const CategorySlider = ({
    data,
    menuItem,
    atts,
    filter,
    params,
    cartId,
    setToggleCart,
    setCurrentCart,
    menuCategory,
    selectedFilters,
    setSelectedFilters,
    setShowMenuBuilderSliders,
    styleOptions,
}) => {
    return (
        <div className='headless-menu-builder-section'>
            <div className='headless-menu-builder-title'>
                <div className='headless-shortcode-title-wrapper'>
                    <div className='headless-shortcode-title'>
                        {menuItem.headless_menu_builder_cat_emoji ? (
                            <div className='shortcode-title-emoji-left'>
                                {menuItem.headless_menu_builder_cat_emoji}
                            </div>
                        ) : null}
                        <span>{ucwords(menuItem.category)}</span>
                        {menuItem.headless_menu_builder_cat_emoji ? (
                            <div className='shortcode-title-emoji-right'>
                                {menuItem.headless_menu_builder_cat_emoji}
                            </div>
                        ) : null}
                    </div>
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setSelectedFilters(filter);
                            setShowMenuBuilderSliders(false);
                        }}
                        className={`${styleOptions.buttonClass ? styleOptions.buttonClass + ' btn-sm headless-title-button' : 'headless-button headless-button-sm headless-title-button'}`}
                    >
                        Shop all {ucwords(menuItem.category)}
                    </a>
                </div>
            </div>
            <ProductsGrid
                data={data}
                atts={atts}
                menuFilter={filter}
                params={params}
                cartId={cartId}
                setToggleCart={setToggleCart}
                setCurrentCart={setCurrentCart}
                menuCategory={menuCategory}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                setShowMenuBuilderSliders={setShowMenuBuilderSliders}
            />
        </div>
    );
};

export default CategorySlider;
