import React, { useState, useRef, useEffect } from 'react';
import {
    getProducts,
    getSpecialsMenu,
    getStaffPicksMenu,
} from '../../api/methods';
import LoadingSpinner from '../LoadingSpinner';

const ShowMore = ({
    retailerId,
    selectedFilters,
    productsToDisplay,
    setProductsToDisplay,
    paginationOffset,
    setPaginationOffset,
    paginationLimit,
    specialsList,
    selectedSpecial,
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadMoreButtonRef = useRef(null);

    /**
     * Use scroll to update pagination offset, which loads more products.
     * @returns void
     */
    const handleScroll = async () => {
        const loadMoreButton = loadMoreButtonRef.current;
        if (!loadMoreButton || isFetching) return;

        const { top } = loadMoreButton.getBoundingClientRect();
        const isButtonVisible = top - window.innerHeight <= 100;

        if (isButtonVisible && !isLoading) {
            setPaginationOffset((prevOffset) => prevOffset + 1);
        }
    };

    let debounceTimer;
    /**
     * Debounce `handleScroll`. Attach to scroll listener.
     */
    const debounceScroll = () => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {
            handleScroll();
        }, 300);
    };

    /**
     * Load and display more products when pagination offset is updated.
     */
    useEffect(() => {
        const loadMore = async () => {
            setIsFetching(true);
            setIsLoading(true);

            let newProducts;
            if (selectedFilters.staffpicks) {
                newProducts = await getStaffPicksMenu(
                    retailerId,
                    paginationLimit * paginationOffset,
                    paginationLimit
                );
            } else if (selectedFilters.specials) {
                if (selectedSpecial) {
                    newProducts = await getSpecialsMenu(
                        retailerId,
                        paginationLimit * paginationOffset,
                        paginationLimit,
                        [selectedSpecial.id]
                    );
                } else {
                    newProducts = await getSpecialsMenu(
                        retailerId,
                        paginationLimit * paginationOffset,
                        paginationLimit,
                        specialsList.map((specials) => specials.id)
                    );
                }
            } else {
                newProducts = await getProducts(
                    retailerId,
                    paginationLimit * paginationOffset,
                    paginationLimit,
                    selectedFilters
                );
            }

            await setProductsToDisplay([...productsToDisplay, ...newProducts]);

            setIsFetching(false);
            setIsLoading(false);
        };

        if (paginationOffset !== 1) {
            loadMore();
        }
    }, [paginationOffset]);

    /**
     * Attach (and remove) scroll event listener.
     */
    useEffect(() => {
        window.addEventListener('scroll', debounceScroll);

        return () => window.removeEventListener('scroll', debounceScroll);
    }, []);

    return (
        <>
            {isLoading && <LoadingSpinner />}
            <button
                ref={loadMoreButtonRef}
                className='headless-button headless-button-small headless-load-more'
                onClick={() =>
                    setPaginationOffset((prevOffset) => prevOffset + 1)
                }
            >
                Load More
            </button>
        </>
    );
};

export default ShowMore;
