import React from 'react';
import { menuFilter } from '../../config';

const SpecialCard = ({
    special,
    setSelectedFilters,
    setShowMenuBuilderSliders,
    setSpecialId,
    retailerMenuLink,
    type,
}) => {
    return (
        <div
            className='headless-specials-card'
            style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 48, 69, 0.99), rgba(0, 48, 69, 0.85)), url(${
                    special.menuDisplayConfiguration.image !=
                    '/images/default-special-card.jpg'
                        ? special.menuDisplayConfiguration.image
                        : 'https://range-headless.s3.amazonaws.com/specials-bg.jpg'
                })`,
            }}
            onClick={() => {
                if ('shortcode' === type) {
                    window.location.href =
                        retailerMenuLink + '?special=' + special.id;
                } else {
                    setSelectedFilters({ ...menuFilter, specials: true });

                    if (setShowMenuBuilderSliders) {
                        setShowMenuBuilderSliders(false);
                    }

                    setSpecialId(special.id);
                }
            }}
        >
            <div className='headless-specials-title'>{special.name}</div>
            <button
                // role='button'
                // href={retailerMenuLink + '?special=' + special.id}
                className='headless-specials-button'
            >
                Shop Offer
            </button>
        </div>
    );
};

export default SpecialCard;
