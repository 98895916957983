import React from 'react';
import SingleProduct from './views/SingleProduct';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import Shop from './views/Shop';

const App = ({ data }) => {
    const { shortcode } = data;

    /**
     * Select component based on shortcode.
     * @returns Component
     */
    const Component = () => {
        switch (shortcode) {
            case 'headlessproducts':
                return <Shop data={data} />;
            case 'singleproduct':
                return <SingleProduct data={data} />;
            case 'headlesscart':
                return <Cart data={data} />;
            case 'headlesscheckout':
                return <Checkout data={data} />;
            default:
                return <h1>Shortcode error!</h1>;
        }
    };

    return <Component />;
};

export default App;
