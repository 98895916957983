import React from 'react';
import { getSpecialsMenu } from '../../api/methods';

const Special = ({
    special,
    setProductsToDisplay,
    retailerId,
    paginationLimit,
    setSelectedSpecial,
    singleSpecialView,
}) => {
    return (
        <div
            className='headless-specials-card'
            style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 48, 69, 0.99), rgba(0, 48, 69, 0.85)), url(${
                    special?.menuDisplayConfiguration?.image !=
                    '/images/default-special-card.jpg'
                        ? special?.menuDisplayConfiguration?.image
                        : 'https://range-headless.s3.amazonaws.com/specials-bg.jpg'
                })`,
            }}
            onClick={async () => {
                if (!singleSpecialView) {
                    setProductsToDisplay(
                        await getSpecialsMenu(retailerId, 0, paginationLimit, [
                            special.id,
                        ])
                    );
                    setSelectedSpecial(special);
                }
            }}
        >
            <div className='headless-specials-title'>{special.name}</div>
            <a role='button' className='headless-specials-button'>
                Shop Offer
            </a>
        </div>
    );
};

export default Special;
