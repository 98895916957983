import React from 'react';

const TodaysHours = ({ hours }) => {
    const date = new Date();
    const day = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    const today = day[date.getDay()];

    return (
        <p className='todays-hours'>
            {hours[today].active
                ? `${hours[today].start} - ${hours[today].end}`
                : 'Not Available'}
        </p>
    );
};

export default TodaysHours;
