import React from 'react';
import startCase from 'lodash.startcase';
import { filterTypes } from '../../config';

const FilterModuleMobile = ({
    filterType,
    title,
    filters,
    handleChange,
    selectedFilters,
    multiple = false,
}) => {
    return (
        <select
            name={filterType}
            id={`headless_${filterType}_mobile`}
            className={`headless-dropdown headless_${filterType}_mobile headless_filter_select`}
            onChange={(e) => handleChange(e.target, filterType)}
            value={
                filterType === filterTypes.category &&
                selectedFilters[filterTypes.specials]
                    ? filterTypes.specials
                    : filterType === filterTypes.category &&
                      selectedFilters[filterTypes.staff_picks]
                    ? filterTypes.staff_picks
                    : selectedFilters[filterType]
            }
            multiple={multiple}
        >
            <option value={''}>{title}</option>
            {filters.map(
                (filter, i) =>
                    filter !== 'NOT_APPLICABLE' &&
                    filter !== 'DEFAULT' && (
                        <option key={i} value={filter?.id || filter}>
                            {startCase(
                                filter?.name
                                    ? filter.name.toLowerCase()
                                    : filter.toLowerCase()
                            )}
                        </option>
                    )
            )}
        </select>
    );
};

export default FilterModuleMobile;
