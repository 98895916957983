import React, { useState, useEffect } from 'react';
import Products from './Products';
import Specials from './Specials';
import StoreBanner from './StoreBanner';
import Filter from '../Filter/Filter';
import WithBlaze from '../Shop/WithBlaze';
import { blazeConfig } from '../../config';
import Surfside from '../Surfside/Surfside';
import {
    getProducts,
    getSpecialsInfo,
    getSpecialsMenu,
    getStaffPicksMenu,
} from '../../api/methods';
import LoadingSpinner from '../LoadingSpinner';
import ShowMore from './ShowMore';

const ProductsGrid = ({
    data,
    atts,
    cartId,
    setCurrentCart,
    setToggleCart,
    selectedFilters,
    setSelectedFilters,
    specialId = null,
    specials = null,
    retailerData = null,
}) => {
    const {
        retailerId,
        retailName,
        surfside,
        filterTerms,
        retailerMenuLink,
        shopEditor,
        customStyleOptions,
    } = data;

    const [productsToDisplay, setProductsToDisplay] = useState(null);

    const [selectedSpecial, setSelectedSpecial] = useState(
        specialId && specials
            ? specials.filter((special) => special.id === specialId)[0]
            : null
    );
    const [specialsList, setSpecialsList] = useState(specials);
    const [displaySpecialsList, setDisplaySpecialsList] = useState(
        specialId && specials ? true : false
    );

    const [paginationOffset, setPaginationOffset] = useState(1);
    const paginationLimit = atts.numProducts === -1 ? 20 : atts.numProducts;

    /**
     * Updates products to display when filters are updated.
     * Also runs on initial page load.
     */
    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getAndSetProducts = async () => {
            setProductsToDisplay(
                await getProducts(
                    retailerId,
                    0,
                    paginationLimit,
                    selectedFilters,
                    signal
                )
            );
        };

        const getAndSetSpecials = async () => {
            if (!specialId) {
                setSpecialsList(await getSpecialsInfo(retailerId, signal));
            }

            if (specialId || specialsList) {
                setProductsToDisplay(
                    await getSpecialsMenu(
                        retailerId,
                        0,
                        paginationLimit,
                        specialId ||
                            specialsList.map((specials) => specials.id),
                        signal
                    )
                );
            }
        };

        const getAndSetStaffPicks = async () => {
            setProductsToDisplay(
                await getStaffPicksMenu(retailerId, 0, paginationLimit)
            );
        };

        if (selectedFilters.staff_picks) {
            getAndSetStaffPicks();
            setDisplaySpecialsList(false);
        } else if (selectedFilters.specials) {
            setDisplaySpecialsList(true);
            getAndSetSpecials();
        } else {
            getAndSetProducts();
            setDisplaySpecialsList(false);
        }

        setPaginationOffset(1);

        return () => controller.abort();
    }, [selectedFilters]);

    useEffect(() => {
        if (specials && specialId) {
            let specialToSet = specials.filter(
                (special) => special.id === specialId
            );
            if (specialToSet.length > 0) {
                setSelectedSpecial(specialToSet[0]);
                setDisplaySpecialsList(true);
            }
        }
    }, [specialId]);

    console.log({
        productsToDisplay,
        paginationOffset,
        selectedFilters,
        retailerData,
        displaySpecialsList,
        selectedSpecial,
        specialsList,
    });

    return (
        <>
            {productsToDisplay ? (
                <>
                    {atts.menu &&
                    surfside.enable &&
                    surfside.showMenuOverlay ? (
                        <Surfside
                            placement='menu-overlay'
                            accountId={surfside.account_id}
                            sourceId={surfside.source_id}
                            retailerId={retailerId}
                            placementId={surfside.placement_ids.menu_overlay}
                        />
                    ) : null}
                    {atts.menu && surfside.enable && surfside.showMenuBanner ? (
                        <Surfside
                            placement='menu-banner'
                            accountId={surfside.account_id}
                            sourceId={surfside.source_id}
                            retailerId={retailerId}
                            placementId={surfside.placement_ids.menu_banner}
                        />
                    ) : null}
                    {atts.menu &&
                    atts.menuType === 'sidebar' &&
                    shopEditor.storeBanner &&
                    retailerData ? (
                        <StoreBanner banner={retailerData.banner} />
                    ) : null}
                    <div
                        className={`headless-shop-container
                        ${atts.slider ? 'headless-slider-wrap' : ''}
                        ${atts.menu ? 'headless-menu' : ''}`}
                    >
                        {atts.menu && atts.menuType === 'sidebar' ? (
                            <Filter
                                retailerId={retailerId}
                                filterTerms={filterTerms}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                                setProductsToDisplay={setProductsToDisplay}
                                paginationLimit={paginationLimit}
                                paginationOffset={paginationOffset}
                                type='menu'
                                styleOptions={customStyleOptions}
                            />
                        ) : null}
                        {atts.title ? (
                            <div className='headless-shortcode-title-wrapper'>
                                <div className='headless-shortcode-title'>
                                    {atts.title}
                                </div>
                                {retailerMenuLink &&
                                selectedFilters.category ? (
                                    <a
                                        href={
                                            retailerMenuLink +
                                            '?category=' +
                                            selectedFilters.category.toLowerCase()
                                        }
                                        className={`${customStyleOptions.buttonClass ? customStyleOptions.buttonClass + ' btn-sm headless-title-button' : 'headless-button headless-button-sm headless-title-button'}`}
                                    >
                                        Shop All
                                    </a>
                                ) : null}
                            </div>
                        ) : null}
                        {!atts.menu &&
                        surfside.enable &&
                        surfside.showCategoryBanner ? (
                            <Surfside
                                placement='category-banner'
                                category={atts.catFilter}
                            />
                        ) : null}
                        <div
                            className={`headless-products-container ${
                                atts.slider
                                    ? 'headless-slider headless-loop-slider blaze-slider'
                                    : ''
                            } ${
                                atts.menu && atts.menuType === 'sidebar'
                                    ? 'has-filter'
                                    : 'no-filter'
                            }`}
                            data-blaze={
                                atts.slider
                                    ? JSON.stringify(blazeConfig(atts.columns))
                                    : null
                            }
                        >
                            {atts.slider ? (
                                <WithBlaze>
                                    {specialsList && displaySpecialsList && (
                                        <Specials
                                            specials={specialsList}
                                            setProductsToDisplay={
                                                setProductsToDisplay
                                            }
                                            retailerId={retailerId}
                                            paginationLimit={paginationLimit}
                                            selectedSpecial={selectedSpecial}
                                            setSelectedSpecial={
                                                setSelectedSpecial
                                            }
                                            styleOptions={customStyleOptions}
                                        />
                                    )}
                                    <Products
                                        products={productsToDisplay}
                                        retailerId={retailerId}
                                        cartId={cartId}
                                        setCurrentCart={setCurrentCart}
                                        setToggleCart={setToggleCart}
                                        retailName={retailName}
                                        styleOptions={customStyleOptions}
                                    />
                                </WithBlaze>
                            ) : (
                                <>
                                    {specialsList && displaySpecialsList && (
                                        <Specials
                                            specials={specialsList}
                                            setProductsToDisplay={
                                                setProductsToDisplay
                                            }
                                            paginationLimit={paginationLimit}
                                            retailerId={retailerId}
                                            selectedSpecial={selectedSpecial}
                                            setSelectedSpecial={
                                                setSelectedSpecial
                                            }
                                            styleOptions={customStyleOptions}
                                        />
                                    )}
                                    <div
                                        className={`
                                            ${
                                                atts.menuDisplay === 'list'
                                                    ? 'headless-products-list'
                                                    : `headless-products-row headless-columns-${atts.columns}`
                                            }
                                        `}
                                    >
                                        <Products
                                            products={productsToDisplay}
                                            numResults={atts.numProducts}
                                            retailerId={retailerId}
                                            display={atts.menuDisplay}
                                            cartId={cartId}
                                            setCurrentCart={setCurrentCart}
                                            setToggleCart={setToggleCart}
                                            retailName={retailName}
                                            styleOptions={customStyleOptions}
                                        />
                                    </div>
                                    <ShowMore
                                        retailerId={retailerId}
                                        productsToDisplay={productsToDisplay}
                                        setProductsToDisplay={
                                            setProductsToDisplay
                                        }
                                        paginationOffset={paginationOffset}
                                        setPaginationOffset={
                                            setPaginationOffset
                                        }
                                        paginationLimit={paginationLimit}
                                        selectedFilters={selectedFilters}
                                        specialsList={specialsList}
                                        selectedSpecial={selectedSpecial}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

export default ProductsGrid;
