import React from 'react';

const Variant = ({ variant, i, selectedVariant, setSelectedVariant }) => {
    return (
        <>
            <div
                className={`headless-sp-variation ${
                    selectedVariant === variant.id ? 'active' : ''
                }`}
                role='button'
                data-variation-id={variant.id}
                onClick={() => setSelectedVariant(variant.id)}
            >
                <div>{variant.option}</div>
            </div>
        </>
    );
};

export default Variant;
