import React from 'react';

const CartBanner = ({
    accountId, sourceId, retailerId, placementId
}) => {
    return (
        <div className='surfside-menu-banner'>
            <div
                className='surf-ad-placement'
                accountId={accountId}
                channelId='bf9bc'
                siteId={sourceId}
                placementId={placementId}
                storeId={retailerId}
                keywords='cart'
                zone='cart'
                type='inline'
                adtypes='0,1'
                width={8}
                height={1}
            >
            </div>
        </div>
    );
};

export default CartBanner;
