import React from 'react';
import TodaysHours from './TodaysHours';

const RetailerInfo = ({ retailerData }) => {
    const { addressObject, name, hours } = retailerData;
    const handleGoBack = () => {
        history.back();
    };

    return (
        <div className='cart-retailer-info'>
            <div className='headless-cart-your-store'>
                <div className='headless-title'>Your store</div>
                <a href='#' onClick={handleGoBack}>Continue shopping</a>
            </div>
            <div className='headless-cart-store-details'>
                <div className='headless-cart-store-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M507.1 129.5l0 0c5.8 9.2 6.4 20.5 2.3 30.1c-3.9 9.2-11.1 14.8-20.1 16c-2 .3-3.9 .4-5.8 .4c-11.7 0-22.2-5.1-29.7-13.2c-9.1-10-22-15.7-35.6-15.7s-26.5 5.8-35.5 15.8c-7.3 8.1-17.7 13.2-29.6 13.2c-11.8 0-22.3-5.1-29.6-13.2c-9.1-10.1-22-15.8-35.6-15.8s-26.5 5.7-35.6 15.8c-7.3 8.1-17.7 13.2-29.6 13.2c-11.8 0-22.3-5.1-29.6-13.2c-9.1-10.1-22-15.8-35.6-15.8s-26.5 5.7-35.6 15.8c-7.3 8.1-17.7 13.2-29.6 13.2c-1.8 0-3.8-.1-5.8-.4c-8.9-1.2-16-6.8-19.9-16c-4.1-9.6-3.5-20.9 2.3-30.1l0 0 0 0L120.4 48H455.6l51.5 81.5zM483.5 224c4.1 0 8.1-.3 12-.8c55.5-7.4 81.8-72.5 52.1-119.4L490.3 13.1C485.2 5 476.1 0 466.4 0H109.6C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c0 0 0 0 0 0c19.6 0 37.5-6.4 51.9-17c4.8-3.5 9.2-7.6 13.2-11.9c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-12c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.8 0 37.8-6.5 52.3-17.3c4.7-3.5 9-7.4 12.9-11.7c3.9 4.3 8.3 8.3 13 11.8c14.5 10.7 32.5 17.2 52.2 17.2c0 0 0 0 0 0zM112 336V254.4c-6.4 1.1-12.9 1.6-19.6 1.6c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3V336v48 64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V384 336 252.6c-4 1-8 1.8-12.3 2.3l-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-6.6 0-13.1-.5-19.4-1.6V336H112zm352 48v64c0 8.8-7.2 16-16 16H128c-8.8 0-16-7.2-16-16V384H464z"/></svg>
                </div>
                <div className='headless-cart-store-address'>
                    <div className='headless-cart-store-name'>{name}</div>
                    <address className='cart-retailer-info__location'>
                        <div>
                            {addressObject.line1} {addressObject.line2}, {addressObject.city}, {addressObject.state}{' '} {addressObject.postalCode}
                        </div>
                    </address>
                </div>
            </div>
            <div className='cart-retailer-info__todays-hours'>
                <div className='cart-retailer-info__todays-hours--delivery'>
                    <div className='cart-ordertype-label'>Delivery</div>
                    <TodaysHours hours={hours.delivery} />
                </div>
                <div className='cart-retailer-info__todays-hours--pickup'>
                    <div className='cart-ordertype-label'>Today's hours</div>
                    <TodaysHours hours={hours.pickup} />
                </div>
            </div>
        </div>
    );
};

export default RetailerInfo;
