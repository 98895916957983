import { filterTypes, menuFilter } from './config';

/**
 * Converts string boolean values to actual booleans.
 * Converts string number values to actual numbers.
 *
 * @param {object} atts Shortcode attributes.
 * @returns {object}
 */
export const shortcodeAttsHelper = (atts) =>
    Object.fromEntries(
        Object.entries(atts).map(([att, value]) => {
            if (value === 'true') {
                return [att, true];
            } else if (value === 'false') {
                return [att, false];
            } else if (typeof value === 'string' && !isNaN(value)) {
                return [att, Number(value)];
            } else {
                return [att, value];
            }
        })
    );

/**
 * Remove excess whitespace and special characters, convert to lowercase and replace spaces with dashes.
 * "This is a String!" -> "this-is-a-string"
 *
 * @param {string} str
 * @returns {string}
 */
export const toLowerWithDashes = (str) =>
    str
        .replace(/[\W_\s]+/g, ' ')
        .trim()
        .replaceAll(' ', '-')
        .toLowerCase();

/**
 * Gets existing checkout info from localStorage, if it exists.
 * @returns {object|false}
 */
export const getExistingCheckout = () => {
    const checkout = window.localStorage.getItem('DUTCHIE_CHECKOUT');

    if (typeof checkout === 'string' && checkout !== 'undefined') {
        return JSON.parse(checkout);
    } else {
        return false;
    }
};

/**
 * Get initial menu filter object.
 *
 * @param {object} atts Shortcode filter atts.
 * @param {URLSearchParams} params URL params.
 *
 * @returns {object}
 */
export const getInitialMenuFilter = (atts, params) => {
    const menuFilterClone = Object.assign({}, menuFilter);

    /**
     * Shortcode attributes.
     * Checked first, so may be overwritten by url param.
     */
    Object.entries(atts).forEach(([key, value]) => {
        if (key === filterTypes.specials && value) {
            menuFilterClone.specials = true;
        } else if (value) {
            if (value === 'ALL') {
                menuFilterClone[key] = '';
            } else {
                menuFilterClone[key] = value;
            }
        }
    });

    /**
     * URL parameters.
     */
    params.forEach((value, key) => {
        if (key === filterTypes.specials || key === 'special') {
            menuFilterClone[filterTypes.specials] = true;
        } else if (key === filterTypes.effects) {
            menuFilterClone[key] = value.split(',').map((v) => v.toUpperCase());
        } else {
            menuFilterClone[key] = value.toUpperCase();
        }
    });

    return menuFilterClone;
};

/**
 * Remove empty `menuFilter` items, as well as specials property.
 * Used before sending in a GQL query.
 *
 * @param {object} menuFilter
 * @returns {object}
 */
export const cleanMenuFilterForQuery = (menuFilter) => {
    const clone = Object.assign({}, menuFilter);
    delete clone.specials;

    return Object.fromEntries(
        Object.entries(clone).filter(([k, v]) => v.length > 0)
    );
};

/**
 * Convert a string to uppercase words like in PHP ucwords()
 * @param {string} str
 * @returns {string}
 */

export const ucwords = (str) => {
    return str.toLowerCase().replace(/(^|\s)[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
};
