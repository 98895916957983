import React from 'react';
import { menuFilter } from '../../config';
import { ucwords } from '../../util';

const CategoryCard = ({
    category,
    image,
    setSelectedFilters,
    setShowSliders,
    type,
    retailerMenuLink,
}) => {
    return (
        <div
            onClick={() => {
                if ('shortcode' === type) {
                    window.location.href =
                        retailerMenuLink + '?category=' + category;
                } else {
                    setSelectedFilters({
                        ...menuFilter,
                        category: category,
                    });

                    if (setShowSliders) {
                        setShowSliders(false);
                    }
                }
            }}
            className='headless-category-card'
        >
            {image ? (
                <img
                    src={`${window.location.origin}${image.sizes.thumbnail}`}
                ></img>
            ) : (
                <div className='default-category-image'></div>
            )}

            <div className='headless-product-title'>{ucwords(category)}</div>
        </div>
    );
};

export default CategoryCard;
