import React from 'react';
import WithBlaze from './WithBlaze';
import { blazeConfigSpecials } from '../../config';
import SpecialCard from './SpecialCard';

const SpecialCards = ({
    specials,
    setSelectedFilters,
    setShowMenuBuilderSliders,
    setSpecialId,
    retailerMenuLink,
    type
}) => {
    return (
        <div
            className={
                'headless-products-container headless-slider headless-loop-slider blaze-slider no-filter'
            }
            data-blaze={JSON.stringify(blazeConfigSpecials())}
        >
            <WithBlaze>
                {specials.map((special, i) => (
                    <SpecialCard
                        key={i}
                        special={special}
                        setSelectedFilters={setSelectedFilters}
                        setShowMenuBuilderSliders={setShowMenuBuilderSliders}
                        setSpecialId={setSpecialId}
                        retailerMenuLink={retailerMenuLink}
                        type={type}
                    />
                ))}
            </WithBlaze>
        </div>
    );
};

export default SpecialCards;
