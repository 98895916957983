import React from 'react';
import Special from './Special';

const Specials = ({
    specials,
    setProductsToDisplay,
    paginationLimit,
    retailerId,
    selectedSpecial,
    setSelectedSpecial,
}) => {
    return (
        <div className='headless-specials'>
            {!selectedSpecial ? (
                specials.map((special, i) => (
                    <Special
                        key={i}
                        special={special}
                        setProductsToDisplay={setProductsToDisplay}
                        paginationLimit={paginationLimit}
                        retailerId={retailerId}
                        setSelectedSpecial={setSelectedSpecial}
                        singleSpecialView={false}
                    />
                ))
            ) : (
                <>
                    <div
                        className='headless-specials-view-all'
                        onClick={() => setSelectedSpecial(null)}
                    >
                        All Specials
                    </div>
                    <Special
                        special={selectedSpecial}
                        setProductsToDisplay={setProductsToDisplay}
                        paginationLimit={paginationLimit}
                        retailerId={retailerId}
                        setSelectedSpecial={setSelectedSpecial}
                        singleSpecialView={true}
                    />
                </>
            )}
        </div>
    );
};

export default Specials;
