import React from 'react';
import FilterDesktop from './FilterDesktop';
import FilterMobile from './FilterMobile';
import { filterTypes, menuFilter } from '../../config';

const Filter = ({
    filterTerms,
    selectedFilters,
    setSelectedFilters,
    styleOptions,
}) => {
    /**
     * Filter logic for desktop and mobile.
     * @param {EventTarget} target
     * @param {string} filterType
     */
    const handleChange = (target, filterType) => {
        /**
         * Staff picks.
         */
        if (
            filterType === filterTypes.category &&
            target.value === filterTypes.staff_picks
        ) {
            setSelectedFilters({
                ...menuFilter,
                [filterTypes.staff_picks]: !selectedFilters.staff_picks,
                [filterTypes.specials]: false,
            });
            /**
             * Specials.
             */
        } else if (
            filterType === filterTypes.category &&
            target.value === filterTypes.specials
        ) {
            setSelectedFilters({
                ...menuFilter,
                [filterTypes.specials]: !selectedFilters.specials,
                [filterTypes.staff_picks]: false,
            });
            /**
             * Effects (array).
             */
        } else if (filterType === filterTypes.effects) {
            if (target.value === '') {
                setSelectedFilters({
                    ...selectedFilters,
                    [filterTypes.effects]: [],
                    [filterTypes.specials]: false,
                    [filterTypes.staff_picks]: false,
                });
            } else {
                setSelectedFilters({
                    ...selectedFilters,
                    [filterTypes.effects]: selectedFilters.effects.includes(
                        target.value
                    )
                        ? [
                              ...selectedFilters.effects.filter(
                                  (filter) => filter !== target.value
                              ),
                          ]
                        : [...selectedFilters.effects, target.value],
                    [filterTypes.specials]: false,
                    [filterTypes.staff_picks]: false,
                });
            }
            /**
             * All other filters.
             */
        } else {
            setSelectedFilters({
                ...selectedFilters,
                [filterType]:
                    selectedFilters[filterType] === target.value
                        ? ''
                        : target.value,
                [filterTypes.specials]: false,
                [filterTypes.staff_picks]: false,
            });
        }
    };

    return (
        <>
            <FilterMobile
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterTerms={filterTerms}
                handleChange={handleChange}
                styleOptions={styleOptions}
            />
            <FilterDesktop
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterTerms={filterTerms}
                handleChange={handleChange}
                styleOptions={styleOptions}
            />
        </>
    );
};

export default Filter;
