/**
 * Blaze configuration
 * @param {int} columns Columns att.
 * @returns {object}
 */
export const blazeConfig = (columns) => {
    return {
        all: {
            loop: true,
            enablePagination: false,
            slidesToScroll: 1,
            slidesToShow: 1,
        },
        '(min-width:767px)': {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
        '(min-width:1199px)': {
            slidesToShow: 3,
            slidesToScroll: 1,
        },
        '(min-width:1399px)': {
            slidesToShow: columns,
            slidesToScroll: 1,
        },
    };
};

export const blazeConfigCategories = () => {
    return {
        all: {
            loop: true,
            enablePagination: false,
            slidesToScroll: 1,
            slidesToShow: 1,
        },
        '(min-width:767px)': {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
        '(min-width:1199px)': {
            slidesToShow: 4,
            slidesToScroll: 1,
        },
        '(min-width:1399px)': {
            slidesToShow: 5,
            slidesToScroll: 1,
        },
        '(min-width:1699px)': {
            slidesToShow: 6,
            slidesToScroll: 1,
        },
    };
};

export const blazeConfigSpecials = () => {
    return {
        all: {
            loop: true,
            enablePagination: false,
            slidesToScroll: 1,
            slidesToShow: 1,
        },
        '(min-width:767px)': {
            slidesToShow: 2,
            slidesToScroll: 1,
        },
        '(min-width:1199px)': {
            slidesToShow: 4,
            slidesToScroll: 1,
        },
    };
};

export const menuFilter = {
    category: '',
    subcategory: '',
    strainType: '',
    brandId: '',
    effects: [],
    specials: false,
    staff_picks: false,
};

export const filterTypes = {
    category: 'category',
    subcategory: 'subcategory',
    brand: 'brandId',
    effects: 'effects',
    strain: 'strainType',
    custom: 'CUSTOM_SECTION',
    specials: 'specials',
    staff_picks: 'staff_picks',
};
