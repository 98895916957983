import React, { useState, useEffect } from 'react';
import groupBy from 'lodash.groupby';
import CartProduct from './CartProduct';

const CartProducts = ({ cart, retailerId, setCurrentCart }) => {
    /**
     * Takes current items in cart and groups them together if they have the same `productId`.
     * Different variants of the same product type have their own item object in the cart.
     * This makes it easier to add variants to the same product in the cart UI.
     */
    const [groupedItems, setGroupedItems] = useState(
        groupBy(cart?.items, (item) => item.productId)
    );

    useEffect(() => {
        setGroupedItems(groupBy(cart?.items, (item) => item.productId));
    }, [cart]);

    return (
        <>
            {Object.keys(groupedItems).map((productId, i) => (
                <CartProduct
                    key={i}
                    items={groupedItems[productId]}
                    retailerId={retailerId}
                    cartId={cart?.id}
                    setCurrentCart={setCurrentCart}
                />
            ))}
        </>
    );
};

export default CartProducts;
