import { print } from 'graphql';

import MenuQuery from './queries/MenuQuery.gql';
import ProductQuery from './queries/ProductQuery.gql';
import RetailerQuery from './queries/RetailerQuery.gql';
import UpdateCheckout from './queries/UpdateCheckout.gql';
import CreateCheckout from './queries/CreateCheckout.gql';
import GetSpecialsList from './queries/GetSpecialsList.gql';
import MenuByStaffPicks from './queries/MenuByStaffPicks.gql';
import FetchCartDetails from './queries/FetchCartDetails.gql';
import AddItemToCheckout from './queries/AddItemToCheckout.gql';
import UpdateItemQuantity from './queries/UpdateItemQuantity.gql';
import MenuByCustomSection from './queries/MenuByCustomSection.gql';
import MenuBySpecialsSection from './queries/MenuBySpecialsSection.gql';
import RemoveItemFromCheckout from './queries/RemoveItemFromCheckout.gql';

const apiEndpoint = rangeHeadlessApi?.apiEndpoint;
const apiKey = atob(rangeHeadlessApi?.apiKey);

/**
 * Make a GQL request to the Dutchie API endpoint.
 *
 * @param {string} query JSON stringified GQL query.
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
const makeQuery = async (query, signal = null) => {
    const myHeaders = new Headers();
    myHeaders.append('authorization', `Bearer ${apiKey}`);
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: query,
        redirect: 'follow',
    };

    if (signal) {
        requestOptions.signal = signal;
    }

    try {
        const response = await fetch(apiEndpoint, requestOptions);
        return await response.json();
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Fuzzy search to get address suggestions.
 * @param {string} query URI encoded string to query.
 * @returns {Promise|error}
 */
export const getAddressSuggestions = async (query) => {
    const tomTomApiKey = process.env.TOMTOMAPI;

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
    };

    try {
        const response = await fetch(
            `https://api.tomtom.com/search/2/search/${query}.json?key=${tomTomApiKey}&countrySet=US,CA&idxSet=PAD,Addr`,
            requestOptions
        );
        return await response.json();
    } catch (error) {
        return console.log('error', error);
    }
};

/**
 * Query all menu products.
 *
 * @param {string} retailerId
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {menuFilter} object menuFilter config.
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const menuQuery = async (
    retailerId,
    offset,
    limit,
    menuFilter,
    signal = null
) => {
    const variables = {
        retailerId,
        offset,
        limit,
        menuFilter,
    };

    return await makeQuery(
        JSON.stringify({ query: print(MenuQuery), variables }),
        signal
    );
};

/**
 * Query menu by custom section.
 *
 * @param {string} retailerId
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {string} menuSectionFilterType Menu filter type. Default 'STAFF_PICKS'.
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const menuByStaffPicks = async (
    retailerId,
    offset,
    limit,
    menuSectionFilterType = "STAFF_PICKS",
    signal = null
) => {
    const variables = {
        retailerId,
        offset,
        limit,
        menuSectionFilterType
    };

    return await makeQuery(
        JSON.stringify({ query: print(MenuByStaffPicks), variables }),
        signal
    );
};

/**
 * Query menu by staff picks.
 *
 * @param {string} retailerId
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {string} sectionType
 * @param {string} sectionName
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const menuByCustomSection = async (
    retailerId,
    offset,
    limit,
    sectionType,
    sectionName,
    signal = null
) => {
    const variables = {
        retailerId,
        offset,
        limit,
        sectionType,
        sectionName,
    };

    return await makeQuery(
        JSON.stringify({ query: print(MenuByCustomSection), variables }),
        signal
    );
};

/**
 * Query a single product.
 *
 * @param {string} retailerId
 * @param {string} productId
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const productQuery = async (retailerId, productId, signal = null) => {
    const variables = {
        retailerId,

        productId,
    };

    return await makeQuery(
        JSON.stringify({ query: print(ProductQuery), variables }),
        signal
    );
};

/**
 * Update item quantity.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} itemId
 * @param {number} quantity
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const updateItemQuantity = async (
    retailerId,
    checkoutId,
    itemId,
    quantity
) => {
    const variables = {
        retailerId,
        checkoutId,
        itemId,
        quantity,
    };

    return await makeQuery(
        JSON.stringify({ query: print(UpdateItemQuantity), variables })
    );
};

/**
 * Update checkout.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} orderType
 * @param {number} pricingType
 * @param {object} address
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const updateCheckout = async (
    retailerId,
    checkoutId,
    orderType,
    pricingType,
    address,
    signal
) => {
    const variables = {
        retailerId,
        checkoutId,
        orderType,
        pricingType,
        address,
    };

    return await makeQuery(
        JSON.stringify({ query: print(UpdateCheckout), variables }),
        signal
    );
};

/**
 * Query the specials list.
 *
 * @param {string} retailerId
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const getSpecialsList = async (retailerId, signal = null) => {
    const variables = {
        retailerId,
    };

    return await makeQuery(
        JSON.stringify({ query: print(GetSpecialsList), variables }),
        signal
    );
};

/**
 * Get products based on specials IDs.
 *
 * @param {string} retailerId
 * @param {int} offset Pagination offset.
 * @param {int} limit Pagination limit.
 * @param {array} specialId
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const menuBySpecialsSection = async (
    retailerId,
    offset,
    limit,
    specialId,
    signal = null
) => {
    const variables = {
        retailerId,
        offset,
        limit,
        specialId,
    };

    return await makeQuery(
        JSON.stringify({ query: print(MenuBySpecialsSection), variables }),
        signal
    );
};

/**
 * Create new checkout object.
 *
 * @param {string} retailerId
 * @param {string} orderType 'DELIVERY' or 'PICKUP'.
 * @param {string} pricingType 'MEDICINAL' or 'RECREATIONAL'.
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const createCheckout = async (
    retailerId,
    orderType,
    pricingType,
    signal = null
) => {
    const variables = {
        retailerId,
        orderType,
        pricingType,
    };

    return await makeQuery(
        JSON.stringify({ query: print(CreateCheckout), variables }),
        signal
    );
};

/**
 * Get existing checkout obj.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const fetchCartDetails = async (
    retailerId,
    checkoutId,
    signal = null
) => {
    const variables = {
        retailerId,
        checkoutId,
    };

    return await makeQuery(
        JSON.stringify({ query: print(FetchCartDetails), variables }),
        signal
    );
};

/**
 * Add product to existing checkout object.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} productId
 * @param {int} quantity
 * @param {string} option String after the '~' in variant ID.
 *
 * @returns {Promise}
 */
export const addItemToCheckout = async (
    retailerId,
    checkoutId,
    productId,
    quantity,
    option
) => {
    const variables = {
        retailerId,
        checkoutId,
        productId,
        quantity,
        option,
    };

    return await makeQuery(
        JSON.stringify({ query: print(AddItemToCheckout), variables })
    );
};

/**
 * Remove item from existing checkout object.
 *
 * @param {string} retailerId
 * @param {string} checkoutId
 * @param {string} itemId
 *
 * @returns {Promise}
 */
export const removeItemFromCheckout = async (
    retailerId,
    checkoutId,
    itemId
) => {
    const variables = {
        retailerId,
        checkoutId,
        itemId,
    };

    return await makeQuery(
        JSON.stringify({ query: print(RemoveItemFromCheckout), variables })
    );
};

/**
 * Get info on a single retailer.
 *
 * @param {string} retailerId
 * @param {AbortSignal|null} signal
 *
 * @returns {Promise}
 */
export const retailerQuery = async (retailerId, signal = null) => {
    const variables = {
        retailerId,
    };

    return await makeQuery(
        JSON.stringify({ query: print(RetailerQuery), variables }),
        signal
    );
};
