import React from 'react';
import Product from './Product';

const Products = ({
    products,
    retailerId,
    cartId,
    setCurrentCart,
    setToggleCart,
    retailName,
    display,
    styleOptions,
}) => {
    return (
        <>
            {products.length === 0 ? (
                <p className='headless-no-products'>
                    Sorry! No products found!
                </p>
            ) : (
                products.map((product, i) => (
                    <Product
                        key={i}
                        product={product}
                        retailerId={retailerId}
                        cartId={cartId}
                        setCurrentCart={setCurrentCart}
                        setToggleCart={setToggleCart}
                        retailName={retailName}
                        display={display}
                        styleOptions={styleOptions}
                    />
                ))
            )}
        </>
    );
};

export default Products;
