import React from 'react';
import CheckoutBtn from './CheckoutBtn';
import PriceSummary from './PriceSummary';
import AddressInput from './AddressInput';
import OrderType from './OrderType';

const PickupOrDelivery = ({
    cart,
    setCurrentCart,
    retailerData,
    styleOptions
}) => {
    return (
        <div className={'headless-pickup-or-delivery'}>
            <OrderType
                cart={cart}
                setCurrentCart={setCurrentCart}
                fulfillmentOptions={retailerData.fulfillmentOptions}
            />
            {cart.orderType === 'DELIVERY' && (
                <AddressInput
                    cart={cart}
                    setCurrentCart={setCurrentCart}
                    retailerId={retailerData.id}
                />
            )}
            <PriceSummary priceSummary={cart.priceSummary} />
            <CheckoutBtn
                url={cart.redirectUrl}
                styleOptions={styleOptions}
            />
        </div>
    );
};

export default PickupOrDelivery;
