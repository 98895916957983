import React from 'react';

const Images = ({ images }) => {
    return (
        <>
            {images.map((image, i) => (
                <div key={i} className='col-lg-6 col-xxl-5'>
                    <div className='headless-sp-images sticky-top'>
                        <img src={image.url} />
                    </div>
                </div>
            ))}
        </>
    );
};

export default Images;
