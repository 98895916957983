import React from 'react';
import OrderType from './OrderType';
import { formattedPrice, totalItemsInCart } from './util';
import CartProducts from './CartProducts';

const CartSidebar = ({
    cart,
    setCurrentCart,
    toggleCart,
    setToggleCart,
    retailerId,
    retailerData,
}) => {
    return (
        <>
            <div
                className='headless-cart-icon openCart'
                role='button'
                onClick={() => setToggleCart(!toggleCart)}
            >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                    <path d='M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16H53.9c7.6 0 14.2 5.3 15.7 12.8l58.9 288c6.1 29.8 32.3 51.2 62.7 51.2H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H191.2c-15.2 0-28.3-10.7-31.4-25.6L152 288H466.5c29.4 0 55-20 62.1-48.5L570.6 71.8c5-20.2-10.2-39.8-31-39.8H99.1C92.5 13 74.4 0 53.9 0H16zm90.1 64H539.5L497.6 231.8C494 246 481.2 256 466.5 256H145.4L106.1 64zM168 456a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm80 0a56 56 0 1 0 -112 0 56 56 0 1 0 112 0zm200-24a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80a56 56 0 1 0 0-112 56 56 0 1 0 0 112z' />
                </svg>
            </div>

            <div
                id='cart-sidebar'
                className={`headless-cart-sidebar position-fixed vh-100 d-flex flex-column bg-white ${
                    toggleCart ? 'headless-show-sidebar' : ''
                }`}
            >
                <div className='headless-cart-sidebar-header'>
                    <div
                        className='headless-cart-sidebar-items'
                        id='cart-items'
                    >
                        <div className='headless-subtitle headless-cart-sidebar-title'>
                            Shopping cart
                        </div>
                    </div>
                    <div
                        className='headless-cart-sidebar-close closeCart'
                        role='button'
                        onClick={() => setToggleCart(!toggleCart)}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 512 512'
                        >
                            <path d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z' />
                        </svg>
                    </div>
                </div>

                <OrderType
                    cart={cart}
                    setCurrentCart={setCurrentCart}
                    fulfillmentOptions={retailerData.fulfillmentOptions}
                />

                <div className='headless-cart-sidebar-contents' id='cart-data'>
                    <CartProducts
                        cart={cart}
                        retailerId={retailerId}
                        setCurrentCart={setCurrentCart}
                    />
                </div>
                <div className='headless-cart-sidebar-bottom'>
                    <div
                        className='headless-cart-sidebar-totals'
                        id='cart-totals'
                    >
                        <p>
                            Current subtotal:{' '}
                            {formattedPrice(cart.priceSummary.subtotal)}
                        </p>
                    </div>
                    <div
                        className='headless-cart-sidebar-checkout'
                        id='cart-checkout'
                    >
                        <a
                            id='cart-checkout-btn'
                            className='btn btn-white'
                            href={`${window.location.origin}/cart`}
                        >
                            Go to Checkout
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartSidebar;
