import React, { useState } from 'react';
import { addItem } from '../../api/methods';
import { toLowerWithDashes } from '../../util';

const Product = ({
    product,
    retailerId,
    cartId,
    setCurrentCart,
    setToggleCart,
    retailName,
    display,
    styleOptions,
}) => {
    const [errors, setErrors] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(
        product.variants[0].id
    );

    const StrainIcon = () => {
        switch (product.strainType) {
            case 'HYBRID':
                return (
                    <span className='headless-strain-icon strain-hybrid'>
                        Hybrid
                    </span>
                );
            case 'INDICA':
                return (
                    <span className='headless-strain-icon strain-indica'>
                        Indica
                    </span>
                );
            case 'SATIVA':
                return (
                    <span className='headless-strain-icon strain-sativa'>
                        Sativa
                    </span>
                );
            case 'HIGH_CBD':
                return (
                    <span className='headless-strain-icon strain-cbd'>
                        CBD
                    </span>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {display === 'list' ? (
                <>
                    <div className='headless-product-list-item' data-product-id={product.id}>
                        <div className='headless-product-list-left'>
                            <div
                                className='headless-product-list-image'
                                style={{ backgroundImage: `url(${product.images[0].url})` }}
                            ></div>
                            <div className='headless-product-list-info'>
                                <h3 className='headless-product-title'>
                                    <a
                                        href={`${window.location.origin
                                            }/menu/product/${toLowerWithDashes(
                                                retailName
                                            )}/${toLowerWithDashes(product.name)}/${retailerId}_${product.id
                                            }/`}
                                    >
                                        {product.name}
                                    </a>
                                </h3>
                                {product.brand ? (
                                    <div className='headless-breadcrumb headless-product-brands'>
                                        {product.brand.name}
                                    </div>
                                ) : null}
                                <div className='headless-list-info-details'>
                                    <div className='headless-list-category'>
                                        {product.category}
                                    </div>
                                    {product.strainType ? (
                                        <div className='headless-product-strain'>
                                            <StrainIcon />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='headless-product-list-right'>
                            {product.variants.length > 0 && (
                                <select
                                    className='headless-dropdown light'
                                    placeholder='Select an option'
                                    onChange={(e) => setSelectedVariant(e.target.value)}
                                >
                                    {product.variants.map((variant, i) => (
                                        <option key={i} value={variant.id}>
                                            {variant.option}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {cartId && (
                                <div className='headless-add-to-cart'>
                                    <button
                                        className={`${styleOptions.buttonClass ? styleOptions.buttonClass + ' btn-sm' : 'headless-button headless-button-sm headless-cart-button'}`}
                                        onClick={async () => {
                                            let response = await addItem(
                                                retailerId,
                                                cartId,
                                                1,
                                                selectedVariant
                                            );

                                            if (response.hasOwnProperty('errors')) {
                                                setErrors(
                                                    response.errors.map((error) => {
                                                        if (error?.extensions?.errors) {
                                                            return error.extensions.errors.map(
                                                                (extError) =>
                                                                    extError.detail
                                                            );
                                                        } else {
                                                            return error.message;
                                                        }
                                                    })
                                                );
                                            } else {
                                                setCurrentCart(response);
                                                setToggleCart(true);
                                                setErrors([]);
                                            }
                                        }}
                                    >
                                        Add to Cart
                                    </button>
                                </div>
                            )}
                            {errors.length > 0 &&
                                errors.map((error, i) => (
                                    <small key={i}>
                                        <code>{error}</code>
                                    </small>
                            ))}
                        </div>
                    </div>
                    <div className='headless-product-list-hr'></div>
                </>
            ) : (
                <div className='headless-product-card' data-product-id={product.id}>
                    {/* {isSpecial ? (
                        <div className='headless-ribbon-wrap'>
                            <div className='headless-ribbon-green'>ON SALE!</div>
                        </div>
                    ) : null} */}

                    <div
                        className='headless-product-image'
                        style={{ backgroundImage: `url(${product.images[0].url})` }}
                    ></div>

                    {product.brand ? (
                        <div className='headless-product-brands'>
                            {product.brand.name}
                        </div>
                    ) : (
                        <div className='headless-product-brands'>
                            <span style={{ opacity: 0, visibility: 'hidden' }}>
                                NO BRAND
                            </span>
                        </div>
                    )}

                    <h3 className='headless-product-title'>
                        <a
                            href={`${
                                window.location.origin
                            }/menu/product/${toLowerWithDashes(
                                retailName
                            )}/${toLowerWithDashes(product.name)}/${retailerId}_${
                                product.id
                            }/`}
                        >
                            {product.name}
                        </a>
                    </h3>

                    <div className='headless-product-bottom'>
                        <hr className='headless-hr' />

                        <div className='headless-product-categories-wrapper'>
                            <div className='headless-product-categories'>
                                {product.category}
                            </div>
                            {product.strainType ? (
                                <div className='headless-product-strain'>
                                    <StrainIcon />
                                </div>
                            ) : null}
                        </div>

                        <hr className='headless-hr' />

                        {product.variants.length > 1 && (
                            <select
                                className='headless-dropdown light'
                                placeholder='Select an option'
                                onChange={(e) => setSelectedVariant(e.target.value)}
                            >
                                {product.variants.map((variant, i) => (
                                    <option key={i} value={variant.id}>
                                        {variant.option}
                                    </option>
                                ))}
                            </select>
                        )}

                        {cartId && (
                            <div className='headless-add-to-cart'>
                                <button
                                    className={`${styleOptions.buttonClass ? styleOptions.buttonClass + ' btn-sm' : 'headless-button headless-cart-button'}`}
                                    onClick={async () => {
                                        let response = await addItem(
                                            retailerId,
                                            cartId,
                                            1,
                                            selectedVariant
                                        );

                                        if (response.hasOwnProperty('errors')) {
                                            setErrors(
                                                response.errors.map((error) => {
                                                    if (error?.extensions?.errors) {
                                                        return error.extensions.errors.map(
                                                            (extError) =>
                                                                extError.detail
                                                        );
                                                    } else {
                                                        return error.message;
                                                    }
                                                })
                                            );
                                        } else {
                                            setCurrentCart(response);
                                            setToggleCart(true);
                                            setErrors([]);
                                        }
                                    }}
                                >
                                    Add to Cart
                                </button>
                            </div>
                        )}
                        {errors.length > 0 &&
                            errors.map((error, i) => (
                                <small key={i}>
                                    <code>{error}</code>
                                </small>
                            ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default Product;
