import React from 'react';
import { formattedPrice } from './util';

const PriceSummary = ({ priceSummary}) => {
    const { subtotal, fees, taxes, total } = priceSummary;

    return (
        <div className='headless-cart-price-summary'>
            <div className='headless-cart-price-item'>
                <div>Subtotal</div>
                <div>{formattedPrice(subtotal)}</div>
            </div>
            <div className='headless-cart-price-item'>
                <div>Estimated service fees</div>
                <div>{formattedPrice(fees)}</div>
            </div>
            <div className='headless-cart-price-item'>
                <div>Estimated taxes</div>
                <div>{formattedPrice(taxes)}</div>
            </div>
            <div className='headless-cart-price-item headless-cart-price-total'>
                <div>Estimated total</div>
                <div className='headless-cart-price-total'>{formattedPrice(total)}</div>
            </div>
        </div>
    );
};

export default PriceSummary;
