import React from 'react';

const MenuBanner = ({
    accountId, sourceId, retailerId, placementId, category
}) => {
    if (category) {
        return (
            <div className='surfside-menu-banner'>
                <div
                    className='surf-ad-placement'
                    accountId={accountId}
                    channelId='bf9bc'
                    siteId={sourceId}
                    placementId={placementId}
                    storeId={retailerId}
                    keywords='{category}'
                    zone='menu'
                    type='inline'
                    adtypes='0,1'
                    width={8}
                    height={1}
                >
                </div>
            </div>
        );
    } else {
        return (
            <div className='surfside-menu-banner'>
                <div
                    className='surf-ad-placement'
                    accountId={accountId}
                    channelId='bf9bc'
                    siteId={sourceId}
                    placementId={placementId}
                    storeId={retailerId}
                    keywords='menu'
                    zone='menu'
                    type='inline'
                    adtypes='0,1'
                    width={8}
                    height={1}
                >
                </div>
            </div>
        );
    }
};

export default MenuBanner;
