import React from 'react';

const Effects = ({ effects }) => {
    const EffectIcon = ({ effect }) => {
        switch (effect) {
            case 'CALM':
                return (
                    <div className='headless-effect-box'>
                        <span className='headless-effects-icon'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                style={{ fill: 'rgb(216, 140, 81)' }}
                                viewBox='0 0 512 512'
                            >
                                <path d='M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm164.1 69.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zm47.1-91.9c-17.6-23.5-52.8-23.5-70.4 0c-5.3 7.1-15.3 8.5-22.4 3.2s-8.5-15.3-3.2-22.4c30.4-40.5 91.2-40.5 121.6 0c5.3 7.1 3.9 17.1-3.2 22.4s-17.1 3.9-22.4-3.2zm89.6 0c-5.3 7.1-15.3 8.5-22.4 3.2s-8.5-15.3-3.2-22.4c30.4-40.5 91.2-40.5 121.6 0c5.3 7.1 3.9 17.1-3.2 22.4s-17.1 3.9-22.4-3.2c-17.6-23.5-52.8-23.5-70.4 0z' />
                            </svg>
                        </span>
                        <span style={{ color: 'rgb(216, 140, 81)' }}>Calm</span>
                    </div>
                );
            case 'ENERGETIC':
                return (
                    <div className='headless-effect-box'>
                        <span className='headless-effects-icon'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 448 512'
                                style={{ fill: '#eecb42' }}
                            >
                                <path d='M16 288L320 0 260.7 197.6 432 224 128 512l59.3-197.6L16 288z' />
                            </svg>
                        </span>
                        <span style={{ color: '#eecb42' }}>Energetic</span>
                    </div>
                );
            case 'HAPPY':
                return (
                    <div className='headless-effect-box'>
                        <span className='headless-effects-icon'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                style={{ fill: '#FC354C' }}
                                viewBox='0 0 512 512'
                            >
                                <path d='M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM256 400c52 0 97.1-32.8 115.7-80H140.3c18.6 47.2 63.7 80 115.7 80zM100 288h32.2H379.8 412c-1.1 11-3.2 21.7-6.4 32c-20 64.9-79.4 112-149.6 112s-129.6-47.1-149.6-112c-3.2-10.3-5.3-21-6.4-32zm56.5-99.5c-6.2 7.2-10.8 16.8-12.9 25.4l-31-7.8c3.2-12.7 9.8-27.1 19.9-38.6C142.4 155.9 157.2 146 176 146s33.6 9.9 43.7 21.5c10 11.5 16.7 25.9 19.9 38.6l-31 7.8c-2.1-8.6-6.7-18.2-12.9-25.4c-6.2-7.1-12.8-10.5-19.5-10.5s-13.4 3.4-19.5 10.5zm147.1 25.4l-31-7.8c3.2-12.7 9.8-27.1 19.9-38.6C302.4 155.9 317.2 146 336 146s33.6 9.9 43.7 21.5c10 11.5 16.7 25.9 19.9 38.6l-31 7.8c-2.1-8.6-6.7-18.2-12.9-25.4c-6.2-7.1-12.8-10.5-19.5-10.5s-13.4 3.4-19.5 10.5c-6.2 7.2-10.8 16.8-12.9 25.4z' />
                            </svg>
                        </span>
                        <span style={{ color: '#FC354C' }}>Happy</span>
                    </div>
                );
            case 'RELAXED':
                return (
                    <div className='headless-effect-box'>
                        <span className='headless-effects-icon'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                style={{ fill: '#316DA5' }}
                                viewBox='0 0 640 512'
                            >
                                <path d='M448 64c53 0 96 43 96 96v37.5c10-3.5 20.8-5.5 32-5.5V160c0-70.7-57.3-128-128-128H192C121.3 32 64 89.3 64 160v32c11.2 0 22 1.9 32 5.5V160c0-53 43-96 96-96H448zM96 320v96 16c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V288c0-17.7 14.3-32 32-32s32 14.3 32 32v32zM80 480c20.9 0 38.7-13.4 45.3-32H514.7c6.6 18.6 24.4 32 45.3 32h32c26.5 0 48-21.5 48-48V288c0-35.3-28.7-64-64-64s-64 28.7-64 64H128c0-35.3-28.7-64-64-64s-64 28.7-64 64V432c0 26.5 21.5 48 48 48H80zm48-64V320H512v96H128zm416 16V416 320 288c0-17.7 14.3-32 32-32s32 14.3 32 32V432c0 8.8-7.2 16-16 16H560c-8.8 0-16-7.2-16-16z' />
                            </svg>
                        </span>
                        <span style={{ color: '#316DA5' }}>Relaxed</span>
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <>
            {effects.length > 0 ? (
                <div className='headless-sp-analyzer'>
                    <div className='headless-sp-effects'>
                        <div className='headless-breadcrumb'>
                            Product Effects
                        </div>
                        <div className='headless-sp-effects-icons'>
                            {effects.map((effect, i) => (
                                <span key={i} className='headless-effects-span'>
                                    <EffectIcon effect={effect} />
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Effects;
