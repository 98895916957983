import React from 'react';
import SpecialCards from '../Shop/SpecialCards';

const SpecialsSlider = ({
    menuItem = null,
    atts,
    filter,
    selectedFilters,
    setSelectedFilters,
    setShowMenuBuilderSliders = null,
    specials,
    setSpecialId,
    retailerMenuLink,
    type,
    styleOptions,
}) => {
    return (
        <div className='headless-menu-builder-section'>
            <div className='headless-menu-builder-title'>
                <div className='headless-shortcode-title-wrapper'>
                    <div className='headless-shortcode-title'>
                        {menuItem &&
                        menuItem.headless_menu_builder_specials_emoji ? (
                            <div className='shortcode-title-emoji-left'>
                                {menuItem.headless_menu_builder_specials_emoji}
                            </div>
                        ) : null}
                        {atts.title ? (
                            <span>{atts.title}</span>
                        ) : (
                            <span>Specials</span>
                        )}
                        {menuItem &&
                        menuItem.headless_menu_builder_specials_emoji ? (
                            <div className='shortcode-title-emoji-right'>
                                {menuItem.headless_menu_builder_specials_emoji}
                            </div>
                        ) : null}
                    </div>
                    <a
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if ('shortcode' === type) {
                                window.location.href =
                                    retailerMenuLink + '?specials';
                            } else {
                                setSelectedFilters(filter);

                                if (setShowMenuBuilderSliders) {
                                    setShowMenuBuilderSliders(false);
                                }
                            }
                        }}
                        className={`${styleOptions.buttonClass ? styleOptions.buttonClass + ' btn-sm headless-title-button' : 'headless-button headless-button-sm headless-title-button'}`}
                    >
                        Shop all specials
                    </a>
                </div>
            </div>
            <SpecialCards
                atts={atts}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                setShowMenuBuilderSliders={setShowMenuBuilderSliders}
                specials={specials}
                setSpecialId={setSpecialId}
                retailerMenuLink={retailerMenuLink}
                type={type}
            />
        </div>
    );
};

export default SpecialsSlider;
